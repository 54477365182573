import { useEffect, useId, useRef, useState } from 'react'
import { DiamondIcon } from '../../../components/DiamondIcon'
import clsx from 'clsx'
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import {
    ShoppingCartIcon, ChevronDownIcon, ChevronUpIcon
} from '@heroicons/react/24/outline'
import { MapContainer, TileLayer, Marker, useMapEvents, useMap } from "react-leaflet";

import L from "leaflet";
import GpsAddressPicker from '../../../components/Inputs/GpsAddressPicker'
import FreightCalculator from '../../../components/Inputs/FreightCalculator'
import Cookies from 'universal-cookie';
import StoreApi from '../../../services/StoreApi'

const cookies = new Cookies();

interface Produto {
    id: number;
    nome: string;
    quantidade: number;
    preco: number;
}
interface CarrinhoProps {
    parametros: {
        carrinho: Produto[]; 
        parametros: any; 
    };
    onCarrinhoChange: (novoCarrinho: any[]) => void; // Tipagem explícita da função
}

export function Carrinho({ parametros, onCarrinhoChange }: CarrinhoProps) {

    const [isMobile, setIsMobile] = useState(false);
    const [carrinhoAberto, setCarrinhoAberto] = useState(false);
    let [paymentOption, setPaymentOption] = useState<string | null>('dinheiro')
    const [address, setAddress] = useState<string>('');
    const [numero, setNumero] = useState<string | number>('');
    const [complemento, setComplemento] = useState<string>('');
    const [numeroDelivery, setNumeroDelivery] = useState<string | number>('');
    const [complementoDelivery, setComplementoDelivery] = useState<string>('');
    let [abaAberta, setAbaAberta] = useState<string | null>('')
    const [deliveryOption, setDeliveryOption] = useState<string>('pickup');
    const [produtos, setProdutos] = useState([] as any);
    const [addressEmpresa, setAddressEmpresa] = useState<string>('');
    const [radius, setRadius] = useState<number>(parametros.parametros.raioCobertura ?? 0);
    const [troco, setTroco] = useState(0);
    const [total, setTotal] = useState((produtos ?? produtos).reduce(
        (soma: number, produto: { quantidade: number; preco: number }) =>
            soma + produto.quantidade * produto.preco, 0));

    const [storeCoordinates, setStoreCoordinates] = useState<[number, number]>(parametros.parametros.coordenadas.split(',').map(Number));
    const [deliveryCoordinates, setDeliveryCoordinates] = useState<[number, number]>([-23.561684, -46.655981]);

    const [frete, setFrete] = useState<{ cost: number | null; message: string }>({
        cost: null,
        message: '',
    });

    const icon = new L.Icon({
        iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
        iconSize: [25, 41],
        iconAnchor: [12, 41],
    });

    const handleComplementoChange = (newComplemento: string) => {
        setComplemento(newComplemento);
        setComplementoDelivery(newComplemento);
    };

    useEffect(() => {
        setTotal(produtos.reduce(
            (soma: number, produto: { quantidade: number; preco: number }) =>
                soma + produto.quantidade * produto.preco,
            0
        ));
    }, [produtos]);

    useEffect(() => {
        setProdutos(parametros.carrinho);
    }, [parametros.carrinho]);

    const handleClientCoordinatesChange = (coordinates: [number, number]) => {
        setDeliveryCoordinates(coordinates);
    };

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 768);
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const aumentarQuantidade = (id: any) => {
        setProdutos((prev: any) => {
            const updatedProdutos = prev.map((produto: any) =>
                produto.id === id
                    ? { ...produto, quantidade: produto.quantidade + 1 }
                    : produto
            );
            setTotal(updatedProdutos.reduce(
                (soma: number, produto: { quantidade: number; preco: number }) =>
                    soma + produto.quantidade * produto.preco,
                0
            ));
            onCarrinhoChange(updatedProdutos);
            return updatedProdutos;
        });
    };

    const diminuirQuantidade = (id: any) => {
        setProdutos((prev: any) => {
            const updatedProdutos = prev
                .map((produto: any) =>
                    produto.id === id
                        ? { ...produto, quantidade: produto.quantidade - 1 }
                        : produto
                )
                .filter((produto: any) => produto.quantidade > 0); 
    
            setTotal(
                updatedProdutos.reduce(
                    (soma: number, produto: { quantidade: number; preco: number }) =>
                        soma + produto.quantidade * produto.preco,
                    0
                )
            );
            onCarrinhoChange(updatedProdutos);
            return updatedProdutos;
        });
    };

    const removerProduto = (id: any) => {
        setProdutos((prev: any) => {
            const updatedProdutos = prev.filter((produto: any) => produto.id !== id);
    
            setTotal(
                updatedProdutos.reduce(
                    (soma: number, produto: { quantidade: number; preco: number }) =>
                        soma + produto.quantidade * produto.preco,
                    0
                )
            );
            onCarrinhoChange(updatedProdutos);
            return updatedProdutos;
        });
    };

    const formatCurrency = (val: number, sign: string) => {
        const c = new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: sign,
        }).format(val);

        const z = new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: sign,
        }).format(0);

        if (c !== z) {
            return c;
        }
        else {
            return z;
        }
    };

    const handleAddressChange = (newAddress: any) => {
        setAddress(newAddress);
    };

    const handleNumeroChange = (newNumero: string | number) => {
            setNumero(newNumero);
            setNumeroDelivery(newNumero);
    };

    const handleFreteChange = (newFrete: { cost: number | null; message: string }) => {
        setFrete(newFrete);

    };

    return (
        <div
            className={`${isMobile
                ? "fixed bottom-0 left-0 w-full z-20 bg-white shadow-lg"
                : "fixed bottom-0 right-10 w-96 z-20 bg-white shadow-lg rounded-md"
                }`}
        >
            {/* Carrinho */}
            <button
                className="flex w-full items-center bg-gray-100 rounded-lg shadow hover:bg-gray-200 transition p-2"
                onClick={() => setCarrinhoAberto(!carrinhoAberto)}
            >
                <ShoppingCartIcon
                    aria-hidden="true"
                    className="h-6 w-6 text-gray-400 hover:text-gray-500"
                />
                <div className="flex-1">
                    <span className="block text-sm font-semibold text-gray-800">
                        Carrinho
                    </span>
                    <span className="block text-xs text-gray-500">
                        {produtos !== undefined && produtos.length > 0 ? produtos.length : 0} itens
                    </span>
                </div>
                {carrinhoAberto ? (
                    <ChevronUpIcon className="h-5 w-5 text-gray-400 ml-auto" />
                ) : (
                    <ChevronDownIcon className="h-5 w-5 text-gray-400 ml-auto" />
                )}
            </button>

            {/* Dropdown / Modal */}
            {carrinhoAberto && (

                <div
                    className={`fixed ${isMobile
                        ? "w-full bottom-0 left-0"
                        : "right-10 bottom-0 w-96"
                        } bg-white rounded-lg shadow-lg p-2`}
                    style={{ transform: isMobile ? "translateY(0)" : "translateY(0)" }}
                >
                    <button
                        className={`flex w-full items-center bg-gray-100 rounded-lg shadow hover:bg-gray-200 transition mb-4 `}
                        onClick={() => setCarrinhoAberto(!carrinhoAberto)}
                    >
                        <ShoppingCartIcon
                            aria-hidden="true"
                            className="h-6 w-6 text-gray-400 hover:text-gray-500"
                        />
                        <div className="flex-1">
                            <span className="block text-sm font-semibold text-gray-800">
                                Carrinho
                            </span>
                            <span className="block text-xs text-gray-500">
                                {produtos !== undefined && produtos.length > 0 ? produtos.length : 0} itens
                            </span>
                        </div>
                        {carrinhoAberto ? (
                            <ChevronUpIcon className="h-5 w-5 text-gray-400 ml-auto" />
                        ) : (
                            <ChevronDownIcon className="h-5 w-5 text-gray-400 ml-auto" />
                        )}
                    </button>
                    {/* Abas estilo sanfona */}
                    <div className="accordion">
                        {/* Aba Produtos no Carrinho */}
                        <div className="accordion-item ">
                            <div className="accordion-content px-2">
                                <div className="grid grid-cols-7 gap-4 px-4 py-2 text-sm font-bold text-gray-800 border-b">
                                    <span className="col-span-2">Produto</span>
                                    <span className="col-span-1">Quantidade</span>
                                    <span className="col-span-1"></span>
                                    <span className="col-span-1">Unidade</span>
                                    <span className="col-span-1"></span>
                                    <span className="col-span-1">Total</span>
                                </div>
                                <ul className="space-y-3 max-h-44 overflow-y-auto">
                                    {produtos !== undefined ? <>
                                        {produtos.length > 0 ? produtos.map((produto: any) => (
                                            <li
                                                key={produto.id}
                                                className="grid grid-cols-7 items-start border-b"
                                            >
                                                <span className="col-span-2 text-sm font-medium text-gray-800 truncate">
                                                    {produto.nome}
                                                </span>
                                              
                                                <div className="flex items-center space-x-2">
                                                    <button
                                                        className="px-1 py-0.5 text-sm bg-gray-200 rounded hover:bg-gray-300"
                                                        onClick={() => {
                                                            diminuirQuantidade(produto.id);
                                                            if (produto.quantidade === 1) {
                                                                removerProduto(produto.id);
                                                            }
                                                        }}
                                                        aria-label={`Diminuir quantidade de ${produto.nome}`}
                                                    >
                                                        -
                                                    </button>
                                                    <span className="text-sm">{produto.quantidade}</span>
                                                    <button
                                                        className="px-1 py-0.5 text-sm bg-gray-200 rounded hover:bg-gray-300"
                                                        onClick={() => aumentarQuantidade(produto.id)}
                                                        aria-label={`Aumentar quantidade de ${produto.nome}`}
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                                <span className="text-sm font-medium text-gray-800">
                                                </span>
                                                <span className="text-sm font-medium text-gray-800">
                                                    {formatCurrency((produto.preco), 'BRL')}
                                                </span>
                                                <span className="">
                                                </span>
                                                <span className="text-sm font-medium text-gray-800">
                                                    {formatCurrency((produto.quantidade * produto.preco), 'BRL')}
                                                </span>
                                            </li>
                                        )) : <>   <span className="block text-xs text-gray-500">Nenhum produto adicionado.</span> </>}
                                    </>
                                        :
                                        <>
                                        </>
                                    }
                                </ul>
                            </div>
                        </div>

                        {/* Aba Opções de Entrega */}
                        <div className="accordion-item mt-1">
                            <button
                                className="accordion-header flex items-center justify-between w-full p-1 mr-10 text-center bg-gray-100 rounded-lg text-gray-800 font-semibold"
                                onClick={() => setAbaAberta(abaAberta === 'entrega' ? null : 'entrega')}
                            >
                                Opções de Entrega
                                {abaAberta === 'entrega' ? (
                                    <ChevronUpIcon className="h-5 w-5 text-gray-400" />
                                ) : (
                                    <ChevronDownIcon className="h-5 w-5 text-gray-400" />
                                )}
                            </button>
                            {abaAberta === 'entrega' && (
                                <div className="accordion-content">
                                    <div className="flex items-center space-x-6">
                                        <label className="flex items-center justify-between cursor-pointer">
                                            {deliveryOption === 'pickup' ?
                                                <input
                                                    type="radio"
                                                    name="deliveryOption"
                                                    value="pickup"
                                                    className="mr-2 text-green-600 focus:ring-green-500"
                                                    defaultChecked
                                                    onChange={() => {
                                                        setDeliveryOption("pickup");
                                                    }}
                                                />
                                                : <input
                                                    type="radio"
                                                    name="deliveryOption"
                                                    value="pickup"
                                                    className="mr-2 text-green-600 focus:ring-green-500"
                                                    onChange={() => {
                                                        setDeliveryOption("pickup");
                                                    }}
                                                />}
                                            <span className="text-gray-800">Retirar na Loja</span>
                                        </label>
                                        <label className="flex items-center justify-center cursor-pointer">
                                            {deliveryOption === 'delivery' ?
                                                <input
                                                    type="radio"
                                                    name="deliveryOption"
                                                    value="delivery"
                                                    className="mr-2 text-green-600 focus:ring-green-500"
                                                    defaultChecked
                                                    onChange={() => {
                                                        setDeliveryOption("delivery");
                                                    }}
                                                />
                                                :
                                                <input
                                                    type="radio"
                                                    name="deliveryOption"
                                                    value="delivery"
                                                    className="mr-2 text-green-600 focus:ring-green-500"
                                                    onChange={() => {
                                                        setDeliveryOption("delivery");
                                                    }}
                                                />
                                            }
                                            <span className="text-gray-800">Receber em Casa</span>
                                        </label>
                                    </div>
                                    {deliveryOption === "delivery" ? (
                                        <div className="mt-3">
                                            <GpsAddressPicker
                                                onCodUserInstance={parametros.parametros.codUserInstance}
                                                initialAddress={address}
                                                initialNumero={numeroDelivery}
                                                initialComplemento={complementoDelivery}
                                                onAddressChange={handleAddressChange}
                                                onNumeroChange={handleNumeroChange}
                                                onComplementoChange={handleComplementoChange}
                                                onCoordinatesChange={handleClientCoordinatesChange}
                                                radius={0}
                                                isStore={false}
                                            />
                                        </div>
                                    ) : (
                                        <div className="w-full h-56 relative mt-3 mb-10">
                                            <label className="flex items-center cursor-pointer mb-1">
                                                {parametros.parametros.endereco} - {parametros.parametros.numero} - {parametros.parametros.complemento}
                                            </label>
                                            <div className="w-full h-64 mb-2" >
                                                <MapContainer
                                                    center={storeCoordinates}
                                                    zoom={50}
                                                    scrollWheelZoom={true}
                                                    style={{ height: "100%", width: "100%", marginBottom: "10px" }}
                                                >
                                                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                                                    <Marker position={storeCoordinates} icon={icon} />
                                                </MapContainer>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                        <div className={`accordion-item ${deliveryOption !== 'delivery' && abaAberta === 'entrega' ? 'mt-10' : "mt-1"}`}>
                            <button
                                className="accordion-header flex items-center justify-between w-full p-1 mr-10 text-center bg-gray-100 rounded-lg text-gray-800 font-semibold"
                                onClick={() => setAbaAberta(abaAberta === 'pagamento' ? null : 'pagamento')}
                            >
                                Pagamento
                                {abaAberta === 'pagamento' ? (
                                    <ChevronUpIcon className="h-5 w-5 text-gray-400" />
                                ) : (
                                    <ChevronDownIcon className="h-5 w-5 text-gray-400" />
                                )}
                            </button>
                            {abaAberta === 'pagamento' && (
                                <div className="accordion-content p-2">
                                    {/* Opções de Pagamento */}
                                    <div className="flex flex-col space-y-4">
                                        <p className="text-sm text-gray-600">
                                            O pagamento será realizado na loja, no momento da retirada do pedido ou na entrega. <br/>Selecione a o metodo de pagamento desejado:
                                        </p>
                                        <label className="flex items-center cursor-pointer">
                                            <input
                                                type="radio"
                                                defaultChecked
                                                name="paymentOption"
                                                value="dinheiro"
                                                className="mr-2 text-green-600 focus:ring-green-500"
                                                onChange={() => setPaymentOption('dinheiro')}
                                            />
                                            <span className="text-gray-800">Dinheiro</span>
                                        </label>
                                        <label className="flex items-center cursor-pointer">
                                            <input
                                                type="radio"
                                                name="paymentOption"
                                                value="cartao"
                                                className="mr-2 text-green-600 focus:ring-green-500"
                                                onChange={() => setPaymentOption('cartao')}
                                            />
                                            <span className="text-gray-800">Cartão de Crédito/Débito</span>
                                        </label>
                                        <label className="flex items-center cursor-pointer">
                                            <input
                                                type="radio"
                                                name="paymentOption"
                                                value="pix"
                                                className="mr-2 text-green-600 focus:ring-green-500"
                                                onChange={() => setPaymentOption('pix')}
                                            />
                                            <span className="text-gray-800">PIX</span>
                                        </label>

                                    </div>
                                    {paymentOption === 'dinheiro' && (
                                        <div className="mt-3">
                                            <label className="text-sm font-semibold text-gray-800">
                                                Precisa de troco para quanto?
                                            </label>
                                            <input
                                                type="number"
                                                value={troco}
                                                onChange={(e) => setTroco(Number(e.target.value))}
                                                className="border rounded px-3 py-1 mb-4 w-full"
                                                min={total + frete.cost}
                                            />
                                        </div>
                                    )}

                                </div>
                            )}
                        </div>
                    </div>
                    {deliveryOption === "delivery" ? (
                        <>
                            {/* Total e Finalização */}
                            <div className="mt-3">
                                <div className="block justify-center text-center items-center font-semibold text-gray-900 bg-gray-100 p-1 rounded-lg shadow-sm">
                                 
                                    {paymentOption !== "" && frete.cost !== null && <p>Forma de Pagamento: {paymentOption} </p>}
                                    {storeCoordinates && deliveryCoordinates ?
                                        <>
                                            <FreightCalculator
                                                storeCoordinates={storeCoordinates}
                                                clientCoordinates={deliveryCoordinates}
                                                radius={radius}
                                                ratePerKm={parametros.parametros.valorRaio}
                                                onFreteChange={handleFreteChange}
                                            />
                                        </> : <></>}
                                    <> <p>Valor total: {frete.cost !== null ? formatCurrency((total + frete.cost), 'BRL') : formatCurrency(total, 'BRL')}</p></>
                                    {address !== "" && <small>Local da Entrega: {address}, {numeroDelivery !== "" && 'Nº: ' + numeroDelivery}{complementoDelivery !== "" && ", " + complementoDelivery}</small>}
                                </div>
                                {formatCurrency(total, 'BRL') !== new Intl.NumberFormat("pt-BR", {
                                    style: "currency",
                                    currency: 'BRL',
                                }).format(0) && frete.cost !== null &&
                                    <button
                                        className="w-full mt-1 py-3 text-base font-medium text-white bg-green-600 rounded-lg shadow-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                                        aria-label="Finalizar Pedido"
                                    >
                                        Finalizar Pedido
                                    </button>}
                            </div>
                        </>
                    ) : (<>

                        <div className="mt-3">
                            <div className="block justify-center text-center items-center font-semibold text-gray-900 bg-gray-100 p-1 rounded-lg shadow-sm">

                                {paymentOption !== "" && <p>Pagamento com {paymentOption}</p>}
                                {paymentOption !== "" && formatCurrency((troco - total), 'BRL')[0] !== '-' &&
                                    <p>{troco !== null || troco !== 0 && "Troco para " + formatCurrency(troco, 'BRL')}</p>}
                                {paymentOption !== "" && formatCurrency((troco - total), 'BRL')[0] !== '-' &&
                                    <p>{troco !== null || troco !== 0 && "Troco será de " + formatCurrency((troco - total), 'BRL')}</p>}

                                <p>Valor total: {total !== 0 ? formatCurrency(total, 'BRL') : formatCurrency(0, 'BRL')}</p>
                            </div>
                            {formatCurrency(total, 'BRL') !== new Intl.NumberFormat("pt-BR", {
                                style: "currency",
                                currency: 'BRL',
                            }).format(0) &&
                                <button
                                    className="w-full mt-1 py-3 text-base font-medium text-white bg-green-600 rounded-lg shadow-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                                    aria-label="Finalizar Pedido"
                                >
                                    Finalizar Pedido
                                </button>}
                        </div>
                    </>)}
                </div>
            )}
        </div>


    )
}
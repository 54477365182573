import React, { useEffect, useState } from 'react';
import AuthService from './../services/Auth';
import { useNavigate } from 'react-router-dom';

function App() {
  const [ServedUsers, setServedUsers] = useState(0);
  const [AutomatedInteractions, setAutomatedInteractions] = useState(0);
  const [HoursSaved, setHoursSaved] = useState(0);
  const [DailyInteractions, setDailyInteractions] = useState(0);
  const [CookiesNotification, setCookiesNotification] = useState(1);
  let navigate = useNavigate();

  useEffect(() => {

    const interval = setInterval(() => {
      if (ServedUsers < 2179) {
        setServedUsers(ServedUsers + 1);
      }
      if (AutomatedInteractions < 12237) {
        setAutomatedInteractions(AutomatedInteractions + 1);
      }
      if (HoursSaved < 111037) {
        setHoursSaved(HoursSaved + 1);
      }
      if (DailyInteractions < 305) {
        setDailyInteractions(DailyInteractions + 1);
      }
    }, 1); // Intervalo de atualização a cada 10 milissegundos (ajuste conforme necessário)
    return () => {
      clearInterval(interval);

    };
  }, [ServedUsers, AutomatedInteractions, HoursSaved, DailyInteractions]);

  return (
    <>
      <section>
        <div className="relative z-20 flex items-center overflow-hidden bg-white">
          <div className="container relative lg:flex md:flex sm:block flex flex-col sm:flex-row md:flex-row px-6 py-8 mx-auto">
            <div className="relative z-20 lg:ml-14 flex flex-col sm:w-3/5 lg:w-3/5">
              <span className="w-20 h-2 mb-12 bg-gray-800"></span>
              <h1 className="flex flex-col text-3xl font-black leading text-gray-800 uppercase font-bebas-neue sm:text-3xl md:text-6x1 lg:text-6x1 ">
                Interações instantâneas,
                <span className="flex flex-col text-4xl font-black leading text-gray-800 uppercase whitespace-nowrap font-bebas-neue sm:text-5xl md:text-5x1 lg:text-5x1">
                  Automática e
                </span>
                <span className="flex flex-col text-4xl font-black leading text-gray-800 uppercase font-bebas-neue sm:text-5xl md:text-5x1 lg:text-5x1">
                  Inteligente.
                </span>
              </h1>

              <p className="text-sm mt-4 text-gray-700 sm:text-base">
                Aumente a experiência do seu cliente com soluções inteligentes e imediatas. <br />
              </p>
              <span className="flex flex-col text-4x3 text-sm text-gray-700 sm:text-5x4 md:text-5x1 lg:text-5x4">
                Você está pronto para vivenciar a revolução no atendimento ao cliente?
              </span>
              <div className="flex mt-4">
                <button
                  onClick={() => navigate("/sign-up")}
                  className="px-4 py-2 mr-4 text-white uppercase bg-green-500 border-2 border-transparent rounded-lg text-md hover:bg-green-400 transition-transform hover:-translate-y-1"
                >
                  Começar Agora
                </button>

              </div>
            </div>
            <div className="flex">
              <img
                src="/empower.gif"
                className="m-auto w-screen sm:w-4/5 md:w-4/5 mt-4 md:ml-14 lg:ml-14"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-green-500">
        <div className="container grid grid-cols-2 gap-8 py-8 mx-auto text-center md:grid-cols-4">
          <div>
            <h5 className="text-7xl font-bold text-white text-sm md:text-3xl">
              <span className="inline text-white">{ServedUsers}</span>
            </h5>
            <p className="text-xs font-medium tracking-wide text-white uppercase">
              Usuários atendidos
            </p>
          </div>
          <div>
            <h5 className="text-7xl font-bold text-white text-sm md:text-3xl">
              <span className="inline text-text-white">{AutomatedInteractions}</span>
            </h5>
            <p className="text-xs font-medium tracking-wide text-white uppercase">
              AInterações automatizadas
            </p>
          </div>
          <div>
            <h5 className="text-7xl font-bold text-white text-sm md:text-3xl">
              <span className="inline text-text-white">{HoursSaved}</span>
            </h5>
            <p className="text-xs font-medium tracking-wide text-white uppercase">
              Horas economizadas
            </p>
          </div>
          <div>
            <h5 className="text-7xl font-bold text-white text-sm md:text-3xl">
              <span className="inline text-white">{DailyInteractions}</span>
            </h5>
            <p className="text-xs font-medium tracking-wide text-white uppercase">
              Interações diárias
            </p>
          </div>
        </div>
      </section>

      <section>
        <div className="relative p-4 bg-white">
          <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-12 lg:items-center">
            <div className="lg:col-start-2 md:pl-20">
              <h4 className="text-2xl font-extrabold leading-8 tracking-tight text-gray-900 sm:leading-9 uppercase">
                Gerencie tudo
              </h4>
              <ul className="mt-10">
                <li>
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center w-12 h-12 text-white bg-green-500 rounded-md">
                        <img
                          className="w-12 rounded-lg"
                          width={20}
                          src="6.png"
                        />
                      </div>
                    </div>
                    <div className="ml-4">
                      <h5 className="text-lg font-bold leading-6 text-gray-900 ">
                        Bate-papo automatizado
                      </h5>
                      <p className="mt-2 text-base leading-6 text-gray-500">
                        Economize tempo, corte custos e nunca mais perca clientes com nosso chat automatizado.
                      </p>
                    </div>
                  </div>
                </li>
                <li className="mt-10">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center w-12 h-12 text-white bg-green-500 rounded-md">
                        <img
                          className="w-12 rounded-lg"
                          width={12}
                          src="8.png"
                        />
                      </div>
                    </div>
                    <div className="ml-4">
                      <h5 className="text-lg font-bold leading-6 text-gray-900">
                        Respostas instantâneas
                      </h5>
                      <p className="mt-2 text-base leading-6 text-gray-500">
                        Respostas instantâneas humanizadas e totalmente personalizáveis ​​no atendimento ao cliente.
                      </p>
                    </div>
                  </div>
                </li>
                <li className="mt-10">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center w-12 h-12 text-white bg-green-500 rounded-md">
                        <img
                          className="w-12 rounded-lg"
                          width={20}
                          src="3.png"
                        />
                      </div>
                    </div>
                    <div className="ml-4">
                      <h5 className="text-lg font-bold leading-6 text-gray-900">
                        Notificações e alertas
                      </h5>
                      <p className="mt-2 text-base leading-6 text-gray-500">
                        Nossas notificações e alertas mantêm você no controle de todas as interações,
                        fornecendo atualizações em tempo real sobre eventos críticos e informações essenciais.
                      </p>
                    </div>
                  </div>
                </li>
                <li className="mt-10">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center w-12 h-12 text-white bg-green-500 rounded-md">
                        <img
                          className="w-12 rounded-lg"
                          width={20}
                          src="5.png"
                        />
                      </div>
                    </div>
                    <div className="ml-4">
                      <h5 className="text-lg font-bold leading-6 text-gray-900">
                        Fluxos Personalizados
                      </h5>
                      <p className="mt-2 text-base leading-6 text-gray-500">
                        Crie jornadas personalizadas para atender às suas necessidades e direcione as conversas de acordo com suas estratégias
                      </p>
                    </div>
                  </div>
                </li>
                <li className="mt-10">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center w-12 h-12 text-white bg-green-500 rounded-md">
                        <img
                          className="w-12 rounded-lg"
                          width={20}
                          src="1.png"
                        />
                      </div>
                    </div>
                    <div className="ml-4">
                      <h5 className="text-lg font-bold leading-6 text-gray-900">
                        Perfil e Acesso
                      </h5>
                      <p className="mt-2 text-base leading-6 text-gray-500">
                        Adicione membros da equipe, gerencie permissões de acesso e organize sua equipe.
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="relative mt-10 -mx-4 lg:mt-0 lg:col-start-1">
              <img
                src="/Clients.png"
                alt="illustration"
                className="relative w-flex mx-auto rounded"
              />
            </div>

          </div>
          <div className="flex items-center justify-center">

            <button
              onClick={() => navigate("/sign-up")}
              className="flex items-center justify-center px-4 py-2 text-green-500 uppercase bg-transparent border-2 border-green-500 rounded-lg hover:bg-green-400 hover:text-white text-md transition-transform hover:-translate-y-1"
            >
              Começar Agora
            </button>
          </div>
        </div>

      </section>
      <section>
        <div className=" flex bg-white flex-col items-center">
          <div className="bg-white w-full mx-auto p-8">
            <img
              alt="profil"
              src="/perfil.jpg"
              className="mx-auto object-cover rounded-lg h-40 w-40 "
            />
            <div className="flex flex-col items-center justify-center md:items-start md:flex-row">

              <div className="w-full md:w-2/3">
                <p className="text-gray-600 w-full md:w-2/3 m-auto text-left text-lg md:text-3xl">
                  <span className="font-bold text-green-500">“ </span>
                  To get social media testimonials like these, keep your customers engaged
                  with your social media accounts by posting regularly yourself
                  <span className="font-bold text-green-500"> ”</span>
                </p>
                <div className="flex items-center justify-center mt-8">
                  <span className="mr-2 text-lg font-semibold text-green-500">
                    Jean Miguel
                  </span>
                  <span className="text-xl font-light text-gray-400">/</span>
                  <span className="ml-2 text-gray-400 text-md">User of Chat Br</span>

                </div>
              </div>
            </div>

          </div>
          <div className='flex flex-row'>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-gray-600"
              fill="none"
              onClick={() => { console.log("oi1") }}
              viewBox="0 0 24 24"
              stroke="currentColor"
              style={{ transform: "scaleX(-1)", cursor: "pointer" }}
            >
              <path

                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-gray-600"
              fill="none"
              onClick={() => { console.log("oi2") }}
              viewBox="0 0 24 24"
              stroke="currentColor"
              style={{ cursor: "pointer" }}
            >
              <path

                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
              />
            </svg>
          </div>
        </div>
      </section>
      <section className="bg-white">
        <div className="relative max-w-screen-xl p-4 px-4 mx-auto bg-white sm:px-6 lg:px-8 py-26">
          <div className="relative">
            <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
              <div className="ml-auto lg:col-start-2 lg:max-w-2xl">
                <p className="text-base font-semibold leading-6 text-green-500 uppercase">
                  Interativo
                </p>
                <h4 className="mt-2 text-2xl font-extrabold leading-8 text-gray-900 sm:text-3xl sm:leading-9">
                  Excelência no atendimento ao cliente é o caminho para o sucesso.
                </h4>
                <p className="mt-4 text-lg leading-6 text-gray-500">
                  Transforme seu atendimento ao cliente com nossa plataforma colaborativa: eficiência, automação e suporte humanizado, tudo em um só lugar. Crie um espaço colaborativo simples, mas poderoso, para toda a sua equipe de atendimento ao cliente. Integre plataformas de mensagens importantes, como WhatsApp, Messenger e Instagram, tudo em um só lugar.
                </p>
                <ul className="gap-6 mt-8 md:grid md:grid-cols-2">
                  <li className="mt-6 lg:mt-0">
                    <div className="flex">
                      <span className="flex items-center justify-center flex-shrink-0 w-6 h-6 text-green-800 bg-green-100 rounded-full drark:bg-transparent">
                        <svg
                          className="w-4 h-4"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path

                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </span>
                      <span className="ml-4 text-base font-medium leading-6 text-gray-500">
                      Bate-papo automatizado
                      </span>
                    </div>
                  </li>
                  <li className="mt-6 lg:mt-0">
                    <div className="flex">
                      <span className="flex items-center justify-center flex-shrink-0 w-6 h-6 text-green-800 bg-green-100 rounded-full drark:bg-transparent">
                        <svg
                          className="w-4 h-4"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path

                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </span>
                      <span className="ml-4 text-base font-medium leading-6 text-gray-500">
                        Membros da equipe
                      </span>
                    </div>
                  </li>
                  <li className="mt-6 lg:mt-0">
                    <div className="flex">
                      <span className="flex items-center justify-center flex-shrink-0 w-6 h-6 text-green-800 bg-green-100 rounded-full drark:bg-transparent">
                        <svg
                          className="w-4 h-4"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path

                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </span>
                      <span className="ml-4 text-base font-medium leading-6 text-gray-500">
                        Suporte 24/24
                      </span>
                    </div>
                  </li>
                  <li className="mt-6 lg:mt-0">
                    <div className="flex">
                      <span className="flex items-center justify-center flex-shrink-0 w-6 h-6 text-green-800 bg-green-100 rounded-full drark:bg-transparent">
                        <svg
                          className="w-4 h-4"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path

                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </span>
                      <span className="ml-4 text-base font-medium leading-6 text-gray-500">
                        Alcance massivo
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="relative mt-10 lg:-mx-4 relative-20 lg:mt-0 lg:col-start-1">
                <div className="relative space-y-4">
                  <div className="flex items-end lg:ml-14 justify-center space-x-4 lg:justify-start">
                    {/* <img
                      className="w-55 rounded-lg md:w-55"
                      width={100}
                      src="digitando.gif"
                    /> */}
                    <img
                      className="w-74 mt-10 lg:ml-14 rounded-lg shadow-lg md:w-74"
                      width={280}
                      src="digitando.gif"
                    />
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-white">

        <div className="max-w-2xl bg-white px-5 m-auto w-full">
          <div className="text-2xl font-extrabold leading-6 text-gray-900 sm:text-2xl sm:leading-9 justify-center mb-4">
            Pronto para economizar tempo e recursos? <br/>
            Tire dúvidas com nosso time.
          </div>
          <div className="grid grid-cols-2 gap-4 max-w-xl m-auto">
            <div className="col-span-2 lg:col-span-1">
              <input
                type="text"
                className="border-solid border-green-500 border-2 p-3 md:text-xl w-full rounded-lg "
                placeholder="Name"
              />
            </div>
            <div className="col-span-2 lg:col-span-1">
              <input
                type="text"
                className="border-solid border-green-500 border-2 p-3 md:text-xl w-full rounded-lg "
                placeholder="Email Address"
              />
            </div>
            <div className="col-span-2">
              <textarea
                cols={30}
                rows={4}
                className="border-solid border-green-500 border-2 p-3 md:text-xl w-full rounded-lg "
                placeholder="Message"
                defaultValue={""}
              />
            </div>
            <div className="col-span-2 text-right">
              <button
                className="px-4 py-2 mr-4 mb-10 text-white uppercase bg-green-500 border-2 border-transparent rounded-lg text-md hover:bg-green-400 transition-transform hover:-translate-y-1"
              >
                Enviar
              </button>
            </div>
          </div>
        </div>
      </section>
      <div className="relative bg-green-500 mt-2">
        <svg
          className="absolute top-0 w-full h-6 -mt-5 sm:-mt-10 sm:h-16 text-green-500 "
          preserveAspectRatio="none"
          viewBox="0 0 1440 54"
        >
          <path
            fill="currentColor"
            d="M0 22L120 16.7C240 11 480 1.00001 720 0.700012C960 1.00001 1200 11 1320 16.7L1440 22V54H1320C1200 54 960 54 720 54C480 54 240 54 120 54H0V22Z"
          />
        </svg>
        <div className="px-4 pt-12 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
          <div className="grid gap-16 row-gap-10 mb-8 lg:grid-cols-6">
            <div className="md:max-w-md lg:col-span-2">
              <button
                onClick={() => navigate("/")}
                aria-label="Go home"
                title="Company"
                className="inline-flex items-center"
              >
                <svg
                  className="w-8 text-teal-accent-400"
                  viewBox="0 0 24 24"
                  strokeLinejoin="round"
                  strokeWidth="2"

                  strokeMiterlimit="10"
                  stroke="currentColor"
                  fill="none"
                >
                  <rect x="3" y="1" width="7" height="12" />
                  <rect x="3" y="17" width="7" height="6" />
                  <rect x="14" y="1" width="7" height="6" />
                  <rect x="14" y="11" width="7" height="12" />
                </svg>
                <span className="ml-2 text-xl font-bold tracking-wide text-gray-100 uppercase">
                  Chat BR
                </span>
              </button>
             {/*  <div className="mt-4 lg:max-w-sm">
                <p className="text-sm text-deep-purple-50">
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium doloremque laudantium, totam rem aperiam.
                </p>
                <p className="mt-4 text-sm text-deep-purple-50">
                  Eaque ipsa quae ab illo inventore veritatis et quasi architecto
                  beatae vitae dicta sunt explicabo.
                </p>
              </div> */}
            </div>
           {/*  <div className="grid grid-cols-2 gap-5 row-gap-8 lg:col-span-4 md:grid-cols-4">
              <div>
                <p className="font-semibold tracking-wide text-teal-accent-400">
                  Category
                </p>
                <ul className="mt-2 space-y-2">
                  <li>
                    <button
                      onClick={() => navigate("/")}
                      className="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                    >
                      News
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => navigate("/")}
                      className="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                    >
                      World
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => navigate("/")}
                      className="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                    >
                      Games
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => navigate("/")}
                      className="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                    >
                      References
                    </button>
                  </li>
                </ul>
              </div>
              <div>
                <p className="font-semibold tracking-wide text-teal-accent-400">
                  Cherry
                </p>
                <ul className="mt-2 space-y-2">
                  <li>
                    <button
                      onClick={() => navigate("/")}
                      className="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                    >
                      Web
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => navigate("/")}
                      className="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                    >
                      eCommerce
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => navigate("/")}
                      className="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                    >
                      Business
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => navigate("/")}
                      className="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                    >
                      Entertainment
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => navigate("/")}
                      className="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                    >
                      Portfolio
                    </button>
                  </li>
                </ul>
              </div>
              <div>
                <p className="font-semibold tracking-wide text-teal-accent-400">
                  Apples
                </p>
                <ul className="mt-2 space-y-2">
                  <li>
                    <button
                      onClick={() => navigate("/")}
                      className="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                    >
                      Media
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => navigate("/")}
                      className="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                    >
                      Brochure
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => navigate("/")}
                      className="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                    >
                      Nonprofit
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => navigate("/")}
                      className="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                    >
                      Educational
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => navigate("/")}
                      className="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                    >
                      Projects
                    </button>
                  </li>
                </ul>
              </div>
              <div>
                <p className="font-semibold tracking-wide text-teal-accent-400">
                  Business
                </p>
                <ul className="mt-2 space-y-2">
                  <li>
                    <button
                      onClick={() => navigate("/")}
                      className="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                    >
                      Infopreneur
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => navigate("/")}
                      className="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                    >
                      Personal
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => navigate("/")}
                      className="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                    >
                      Wiki
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => navigate("/")}
                      className="transition-colors duration-300 text-deep-purple-50 hover:text-teal-accent-400"
                    >
                      Forum
                    </button>
                  </li>
                </ul>
              </div>
            </div> */}
          </div>
          <div className="flex flex-col justify-between pt-5 pb-10 border-t border-deep-purple-accent-200 sm:flex-row">
            <p className="text-sm text-gray-100">
              © Copyright 2023 FDH Inc. All rights reserved.
            </p>
            <div className="flex items-center mt-4 space-x-4 sm:mt-0">
              <button
                onClick={() => navigate("/")}
                className="transition-colors duration-300 text-deep-purple-100 hover:text-teal-accent-400"
              >
                <svg viewBox="0 0 24 24" fill="currentColor" className="h-5">
                  <path d="M24,4.6c-0.9,0.4-1.8,0.7-2.8,0.8c1-0.6,1.8-1.6,2.2-2.7c-1,0.6-2,1-3.1,1.2c-0.9-1-2.2-1.6-3.6-1.6 c-2.7,0-4.9,2.2-4.9,4.9c0,0.4,0,0.8,0.1,1.1C7.7,8.1,4.1,6.1,1.7,3.1C1.2,3.9,1,4.7,1,5.6c0,1.7,0.9,3.2,2.2,4.1 C2.4,9.7,1.6,9.5,1,9.1c0,0,0,0,0,0.1c0,2.4,1.7,4.4,3.9,4.8c-0.4,0.1-0.8,0.2-1.3,0.2c-0.3,0-0.6,0-0.9-0.1c0.6,2,2.4,3.4,4.6,3.4 c-1.7,1.3-3.8,2.1-6.1,2.1c-0.4,0-0.8,0-1.2-0.1c2.2,1.4,4.8,2.2,7.5,2.2c9.1,0,14-7.5,14-14c0-0.2,0-0.4,0-0.6 C22.5,6.4,23.3,5.5,24,4.6z" />
                </svg>
              </button>
              <button
                onClick={() => navigate("/")}
                className="transition-colors duration-300 text-deep-purple-100 hover:text-teal-accent-400"
              >
                <svg viewBox="0 0 30 30" fill="currentColor" className="h-6">
                  <circle cx="15" cy="15" r="4" />
                  <path d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z" />
                </svg>
              </button>
              <button
                onClick={() => navigate("/")}
                className="transition-colors duration-300 text-deep-purple-100 hover:text-teal-accent-400"
              >
                <svg viewBox="0 0 24 24" fill="currentColor" className="h-5">
                  <path d="M22,0H2C0.895,0,0,0.895,0,2v20c0,1.105,0.895,2,2,2h11v-9h-3v-4h3V8.413c0-3.1,1.893-4.788,4.659-4.788 c1.325,0,2.463,0.099,2.795,0.143v3.24l-1.918,0.001c-1.504,0-1.795,0.715-1.795,1.763V11h4.44l-1,4h-3.44v9H22c1.105,0,2-0.895,2-2 V2C24,0.895,23.105,0,22,0z" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        id="cookies-simple-with-dismiss-button"
        className="fixed bottom-0 start-1/2 transform -translate-x-1/2 z-[60] sm:max-w-4xl w-full mx-auto p-6"
      >
        {/* Card */}
        <div className={`p-4 bg-white border border-gray-200 rounded-xl shadow-sm  ${CookiesNotification === 0 && 'hidden'}`}>
          <div className="flex justify-between items-center gap-x-5 sm:gap-x-10">
            <h2 className="text-sm text-gray-600 ">
              By continuing to use this site you consent to the use of cookies in
              accordance with our{" "}
              <button
                className="inline-flex items-center gap-x-1.5 text-blue-600 decoration-2 hover:underline font-medium"
                onClick={() => navigate("/")}
              >
                Cookies Policy.
              </button>
            </h2>
            <button
              type="button"
              onClick={() => { CookiesNotification === 0 ? setCookiesNotification(1) : setCookiesNotification(0) }}
              className="p-2 inline-flex items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent bg-gray-100 text-gray-800 hover:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none "
              data-hs-remove-element="#cookies-simple-with-dismiss-button"
            >
              <span className="sr-only">Dismiss</span>
              <svg
                className="flex-shrink-0 h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}

                strokeLinejoin="round"
              >
                <path d="M18 6 6 18" />
                <path d="m6 6 12 12" />
              </svg>
            </button>
          </div>
        </div>
        {/* End Card */}
      </div>

    </>
  );
}

export default App;

import { useCallback, useEffect, useId, useRef, useState } from 'react'
import { DiamondIcon } from '../../../components/DiamondIcon'
import clsx from 'clsx'
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import {
  ShoppingBagIcon, ChevronDownIcon, ChevronUpIcon
} from '@heroicons/react/24/outline'
import { MapContainer, TileLayer, Marker, useMapEvents, useMap } from "react-leaflet";

import L from "leaflet";
import GpsAddressPicker from '../../../components/Inputs/GpsAddressPicker'
import FreightCalculator from '../../../components/Inputs/FreightCalculator'
import Cookies from 'universal-cookie';
import StoreApi from '../../../services/StoreApi'
import { Carrinho } from './Carrinho'
import DraggableImage from '../../../components/Inputs/DraggableImage'

const cookies = new Cookies();


function ImageClipPaths({ id, ...props }: React.ComponentPropsWithoutRef<'svg'> & { id: string }) {
  return (
    <svg aria-hidden="true" width={0} height={0} {...props}>
      <defs>
        <clipPath id={`${id}-0`} clipPathUnits="objectBoundingBox">
          <path d="M0,0 h0.729 v0.129 h0.121 l-0.016,0.032 C0.815,0.198,0.843,0.243,0.885,0.243 H1 v0.757 H0.271 v-0.086 l-0.121,0.057 v-0.214 c0,-0.032,-0.026,-0.057,-0.057,-0.057 H0 V0" />
        </clipPath>
        <clipPath id={`${id}-1`} clipPathUnits="objectBoundingBox">
          <path d="M1,1 H0.271 v-0.129 H0.15 l0.016,-0.032 C0.185,0.802,0.157,0.757,0.115,0.757 H0 V0 h0.729 v0.086 l0.121,-0.057 v0.214 c0,0.032,0.026,0.057,0.057,0.057 h0.093 v0.7" />
        </clipPath>
        <clipPath id={`${id}-2`} clipPathUnits="objectBoundingBox">
          <path d="M1,0 H0.271 v0.129 H0.15 l0.016,0.032 C0.185,0.198,0.157,0.243,0.115,0.243 H0 v0.757 h0.729 v-0.086 l0.121,0.057 v-0.214 c0,-0.032,0.026,-0.057,0.057,-0.057 h0.093 V0" />
        </clipPath>
      </defs>
    </svg>
  )
}

interface StoreProps {
  parametros: {
    id: number;
    codUserInstance: number;
    url: string;
    nome: string;
    descricao: string;
    imagem: string | null;
    corFundo: string;
    cor1: string;
    cor2: string;
    endereco: string | null;
    numero: number | null;
    complemento: string | null;
    coordenadas: [number, number];
    moeda: string;
    raioCobertura: number;
    valorRaio: number;
    ativo: number;
    created_at: string;
    updated_at: string;
    categorias: any[];
  };
}

interface Categoria {
  id: number;
  codStoreEmpresa: Number;
  nome: string;
  descricao: string;
  produtos: any;
}

interface Produto {
  id: number;
  nome: string;
  quantidade: number;
  preco: number;
}

export function Store({ parametros }: StoreProps) {
  let id = useId()
  let [empresa, setEmpresa] = useState(parametros.nome)
  let [descricaoEmpresa, setDescricaoEmpresa] = useState(parametros.descricao)
  let [tabOrientation, setTabOrientation] = useState('horizontal')
  let [colorBackground, setColorBackground] = useState<string>(parametros.corFundo)
  let [colorPrimary, setColorPrimary] = useState<string>(parametros.cor1)
  let [colorSecondary, setColorSecondary] = useState<string>(parametros.cor2)

  let [selectedCategory, setSelectedCategory] = useState<string>('')
  let [newCategory, setNewCategory] = useState<string>('')
  let [newCategoryDescription, setNewCategoryDescription] = useState<string>('')
  let [selectedProduto, setSelectedProduto] = useState<string>('')
  let [newProduto, setNewProduto] = useState<string>('')
  let [newProdutoDescription, setNewProdutoDescription] = useState<string>('')
  /* endereço entrega */
  const [numero, setNumero] = useState<string | number>('');
  const [complemento, setComplemento] = useState<string>('');
  const [addressEmpresa, setAddressEmpresa] = useState<string>(parametros.endereco ? parametros.endereco : '');
  const [numeroEmpresa, setNumeroEmpresa] = useState<string | number>(parametros.numero ? parametros.numero : '');
  const [complementoEmpresa, setComplementoEmpresa] = useState<string>(parametros.complemento ? parametros.complemento : '');
  const [radius, setRadius] = useState<number>(parametros.raioCobertura ? parametros.raioCobertura : 0);
  const [valorRadius, setValorRadius] = useState<number>(parametros.valorRaio ? parametros.valorRaio : 0);
  const [file, setFile] = useState<File | null>(null);
  const [fileProduto, setFileProduto] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(parametros.imagem ? `https://api.chatbr.xyz/uploads/${parametros.imagem.split('.')[0]}/${parametros.imagem}` : null);
  const [currency, setCurrency] = useState(parametros.moeda ? parametros.moeda : 'BRL');
  const [valorProduto, setValorProduto] = useState(0);
  const [cardapio, setCardapio] = useState(parametros.categorias);
  const [listaCarrinho, setListaCarrinho] = useState([] as any);

  const [storeAtivoCategoria, setStoreAtivoCategoria] = useState(0);
  const [storeAtivoProduto, setStoreAtivoProduto] = useState(0);

  const [storeCoordinates, setStoreCoordinates] = useState<[number, number]>(parametros.coordenadas);
  const [viewMode, setViewMode] = useState('grid'); // 'grid' ou 'carousel'

  useEffect(() => {
    const handleResize = () => {
      setViewMode(window.innerWidth <= 768 ? 'carousel' : 'grid');
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleCarrinhoChange = useCallback((novoCarrinho: Produto[]) => {
    setListaCarrinho(novoCarrinho);
  }, []);

  const handleAddProdutoCarrinho = (prod: any) => {

    setListaCarrinho((prevLista: any) => {
      const produtoExistente = prevLista.find((item: any) => item.id === prod.id);
      if (produtoExistente) {
        return prevLista.map((item: any) =>
          item.id === prod.id
            ? { ...item, quantidade: item.quantidade + 1 }
            : item
        );
      }
      return [...prevLista, { id: prod.id, nome: prod.nome, quantidade: 1, preco: prod.valor }];
    })
  }

  const carregamentoStore = (id: any) => {

    StoreApi.getStoreEmpresa(id).then((response: any) => {
      console.log(response.data);
      parametros = response.data;
      setCardapio(response.data.categorias);
      handleNumeroEmpresaChange(response.data.numero)
      handleComplementoEmpresaChange(response.data.complemento);
    });
  }

  const handleComplementoChange = (newComplemento: string) => {
    setComplemento(newComplemento);
  };

  const handleAddressEmpresaChange = (newAddress: any) => {
    setAddressEmpresa(newAddress);
  };

  const currencies = [
    { code: "USD", name: "Dólar Americano", symbol: "$" },
    { code: "EUR", name: "Euro", symbol: "€" },
    { code: "BRL", name: "Real Brasileiro", symbol: "R$" },
    { code: "JPY", name: "Iene Japonês", symbol: "¥" },
    { code: "GBP", name: "Libra Esterlina", symbol: "£" },
    { code: "AUD", name: "Dólar Australiano", symbol: "A$" },
    { code: "CAD", name: "Dólar Canadense", symbol: "C$" },
    { code: "CHF", name: "Franco Suíço", symbol: "CHF" }
  ];

  const formatCurrency = (val: number, sign: string) => {
    const c = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: sign,
    }).format(val);

    const z = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: sign,
    }).format(0);

    if (c !== z) {
      return c;
    }
    else {
      return z;
    }
  };

  type BackgroundStyle = {
    size: string;
    position: string;
    repeat: string;
    filter: string;
    borderRadius: string;
    boxShadow: string;
  };

  const [backgroundStyle, setBackgroundStyle] = useState<BackgroundStyle>({
    size: "cover",
    position: "center",
    repeat: "no-repeat",
    filter: "none",
    borderRadius: "0px",
    boxShadow: "none",
  });

  const handleImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("Evento disparado:", event); // Verifique se este log aparece
    const selectedFile = event.target.files?.[0];

    console.log("Arquivo selecionado:", selectedFile);

    if (!selectedFile) {
      console.warn("Nenhum arquivo foi selecionado.");
      return;
    }

    setFile(selectedFile);

    const reader = new FileReader();
    reader.onload = () => {
      if (selectedFile) {
        setImageUrl(reader.result as string);
      }
      else {
        if (imageUrl)
          setImageUrl(`https://api.chatbr.xyz/uploads/${imageUrl.split('.')[0]}/${imageUrl}` + reader.result as string);

      }
    };
    reader.onerror = (error) => {
      console.error("Erro ao ler o arquivo:", error);
    };

    reader.readAsDataURL(selectedFile);
  };

  const handleImageProdutoChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("Evento disparado:", event); // Verifique se este log aparece
    const selectedFile = event.target.files?.[0];

    console.log("Arquivo selecionado:", selectedFile);

    if (!selectedFile) {
      console.warn("Nenhum arquivo foi selecionado.");
      return;
    }

    setFileProduto(selectedFile);

    const reader = new FileReader();
    reader.onload = () => {

    };
    reader.onerror = (error) => {
      console.error("Erro ao ler o arquivo:", error);
    };

    reader.readAsDataURL(selectedFile);

  };

  const handleSubmit = async () => {

    const bodyData = {
      codUserInstance: parametros.codUserInstance,
      url: parametros.url,
      nome: empresa || '',
      descricao: descricaoEmpresa || '',
      endereco: addressEmpresa || parametros.endereco,
      numero: numeroEmpresa || numero || parametros.numero,
      complemento: complementoEmpresa || complemento || parametros.complemento,
      corFundo: colorBackground || parametros.corFundo,
      cor1: colorPrimary || parametros.cor1,
      cor2: colorSecondary || parametros.cor2,
      coordenadas: storeCoordinates.join(',') || parametros.coordenadas,
      moeda: currency || parametros.moeda,
      raioCobertura: radius || parametros.raioCobertura,
      valorRaio: valorRadius || parametros.valorRaio,
      ativo: parametros.ativo,
    };

    try {
      StoreApi.updateStoreEmpresa(parametros.codUserInstance, file, bodyData).then((updateStore) => {
        StoreApi.getStoreEmpresa(id).then((response: any) => {
          parametros = response.data;
        });
      });
    } catch (error) {
      console.error("Erro ao atualizar a empresa:", error);
    }

    carregamentoStore(parametros.codUserInstance)
  };

  useEffect(() => {
    let lgMediaQuery = window.matchMedia('(min-width: 1024px)')

    function onMediaQueryChange({ matches }: { matches: boolean }) {
      setTabOrientation(matches ? 'vertical' : 'horizontal')
    }

    onMediaQueryChange(lgMediaQuery)
    lgMediaQuery.addEventListener('change', onMediaQueryChange)

    return () => {
      lgMediaQuery.removeEventListener('change', onMediaQueryChange)
    }
  }, [])

  useEffect(() => {
    carregamentoStore(parametros.codUserInstance)
    if (selectedCategory) {
      const category = cardapio.find((item: Categoria) => item.id === Number(selectedCategory))
      if (category) {
        setNewCategory(category.nome)
        setNewCategoryDescription(category.descricao)
        setStoreAtivoCategoria(category.ativo);
        setNewProduto('')
        setNewProdutoDescription('')
        setValorProduto(0);
        setFileProduto(null);
        setSelectedProduto('');
        setStoreAtivoProduto(0);
      }
    } else {
      setNewCategory('')
      setNewCategoryDescription('')
      setStoreAtivoCategoria(0);
      setNewProduto('')
      setNewProdutoDescription('')
      setValorProduto(0);
      setFileProduto(null);
      setSelectedProduto('');
      setStoreAtivoProduto(0);
    }
  }, [selectedCategory])

  useEffect(() => {
    carregamentoStore(parametros.codUserInstance)
    if (selectedProduto) {

      const category = cardapio.find((item: any) => item.id === Number(selectedCategory))?.produtos
      const produto = category?.find((item: any) => item.id === Number(selectedProduto))
      if (produto) {
        setNewProduto(produto.nome)
        setNewProdutoDescription(produto.descricao)
        setValorProduto(produto.valor)
        setStoreAtivoProduto(produto.ativo);
      }
    } else {
      setNewProduto('')
      setNewProdutoDescription('')
      setValorProduto(0);
      setFileProduto(null);
      setSelectedProduto('');
      setStoreAtivoProduto(0);
    }
  }, [selectedProduto])

  function handleDeleteCategory(selectedCategory: any) {
    try {
      StoreApi.deleteStoreCategoria(selectedCategory).then((createCategoriaStore) => {
        console.log("Categoria atualizada com sucesso:", createCategoriaStore);
        carregamentoStore(parametros.codUserInstance);
        setNewCategory("");
        setNewCategoryDescription("");
        setSelectedCategory("");
        setStoreAtivoCategoria(0);
        setNewProduto('')
        setNewProdutoDescription('')
        setValorProduto(0);
        setFileProduto(null);
        setSelectedProduto('');
        setStoreAtivoProduto(0);
      });
    } catch (error) {
      console.error("Erro ao atualizar a categoria:", error);
    }

  }

  async function handleAddOrEditCategory() {

    const category = cardapio.find((item: any) => item.id === Number(selectedCategory));
    if (selectedCategory !== '') {
      var bodyData = {
        codStoreEmpresa: parametros.id,
        nome: newCategory || '',
        descricao: newCategoryDescription || '',
        ativo: storeAtivoCategoria || category.ativo,
      };

      try {
        const createCategoriaStore = await StoreApi.updateStoreCategoria(selectedCategory, bodyData);
        console.log("Categoria atualizada com sucesso:", createCategoriaStore);
        carregamentoStore(parametros.codUserInstance);
        // Resetar os campos
        setNewCategory('');
        setNewCategoryDescription('');
        setSelectedCategory('');
        setStoreAtivoCategoria(0);
        setNewProduto('')
        setNewProdutoDescription('')
        setValorProduto(0);
        setFileProduto(null);
        setSelectedProduto('');
        setStoreAtivoProduto(0);
      } catch (error) {
        console.error("Erro ao atualizar a categoria:", error);
      }
    } else {
      const bodyData = {
        codStoreEmpresa: parametros.id,
        nome: newCategory || '',
        descricao: newCategoryDescription || '',
        ativo: storeAtivoCategoria,
      };

      try {
        const createCategoriaStore = await StoreApi.createStoreCategoria(bodyData);
        console.log("Categoria criada com sucesso:", createCategoriaStore);
        carregamentoStore(parametros.codUserInstance);
        // Resetar os campos
        setNewCategory('');
        setNewCategoryDescription('');
        setSelectedCategory('');
        setStoreAtivoCategoria(0);
        setNewProduto('')
        setNewProdutoDescription('')
        setValorProduto(0);
        setFileProduto(null);
        setSelectedProduto('');
        setStoreAtivoProduto(0);
      } catch (error) {
        console.error("Erro ao criar a categoria:", error);
      }
    }
  }

  async function handleAddOrEditProduto() {
    const category = cardapio.find((item: any) => item.id === Number(selectedCategory));
    const produto = category?.produtos.find((item: any) => item.id === Number(selectedProduto));

    if (selectedProduto !== '') {
      console.log(category);

      // Dados para editar produto existente
      const bodyDataUpdate = {
        codStoreCategoria: category.id,
        nome: newProduto || produto.nome,
        descricao: newProdutoDescription || produto.descricao,
        valor: valorProduto || produto.valor,
        ativo: storeAtivoProduto || produto.ativo,
      };

      try {
        const resultado = await StoreApi.updateStoreProduto(produto.id, fileProduto, bodyDataUpdate);
        console.log("Produto atualizado com sucesso:", resultado);
        carregamentoStore(parametros.codUserInstance);

        // Resetar os campos após a atualização
        setNewProduto('');
        setNewProdutoDescription('');
        setValorProduto(0);
        setFileProduto(null); // Se você está usando um arquivo, pode resetá-lo
        setSelectedProduto('');
        setStoreAtivoProduto(0);
      } catch (error) {
        console.error("Erro ao atualizar o produto:", error);
      }
    } else {
      // Dados para criar um novo produto
      const bodyDataCreate = {
        codStoreCategoria: category.id,
        nome: newProduto || "",
        descricao: newProdutoDescription || "",
        valor: valorProduto || 0,
        ativo: storeAtivoProduto,
      };

      try {
        // Criando o produto na loja
        const createProdutoStore = await StoreApi.createStoreProduto(bodyDataCreate, fileProduto);
        console.log("Produto criado com sucesso:", createProdutoStore);
        carregamentoStore(parametros.codUserInstance);

        // Resetar os campos após a criação
        setNewProduto('');
        setNewProdutoDescription('');
        setValorProduto(0);
        setFileProduto(null); // Resetando o arquivo se necessário
        setSelectedProduto('');
        setStoreAtivoProduto(0);
      } catch (error) {
        console.error("Erro ao criar o produto:", error);
      }
    }
  }

  function handleDeleteProduto() {
    try {
      StoreApi.deleteStoreProduto(selectedProduto).then((deleteProdutoStore) => {
        console.log("Produto deletado com sucesso:", deleteProdutoStore);
        carregamentoStore(parametros.codUserInstance);
        setNewProduto("");
        setNewProdutoDescription("");
        setValorProduto(0);
        setStoreAtivoProduto(0);
        setFileProduto(null);
        setSelectedProduto("");
      });
    } catch (error) {
      console.error("Erro ao atualizar a categoria:", error);
    }
  }

  const handleStoreCoordinatesChange = (coordinates: [number, number]) => {
    setStoreCoordinates(coordinates);
  };

  const handleNumeroEmpresaChange = (newNumero: string | number) => {
    setNumeroEmpresa(newNumero);
  };

  const handleComplementoEmpresaChange = (newComplemento: string) => {
    setComplementoEmpresa(newComplemento);
  };

  const atualizaCarrinho = {
    carrinho: listaCarrinho, // listaCarrinho deve ser do tipo Produto[]
    parametros: parametros
  };


  return (
    <div style={{ backgroundColor: colorBackground }}>
      {cookies.get('user') !== undefined && (cookies.get('user').permissions[0]?.name === "Plano Teste" || cookies.get('user').permissions[0]?.name === "Plano Basic") ? (

        <div className="container mx-auto p-6">
          <section
            id="editor1"
            className="p-6 bg-gray-100 rounded-lg grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3"
          >
            <div>
              <label htmlFor="empresa-name" className="block text-md font-semibold text-gray-700 mb-1">
                Nome da Empresa:
              </label>
              <input
                id="empresa-name"
                type="text"
                value={empresa}
                onChange={(e) => setEmpresa(e.target.value)}
                className="w-full p-1 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
            </div>

            <div>
              <label htmlFor="descricao-empresa" className="block text-md font-semibold text-gray-700 mb-1">
                Descrição da Empresa:
              </label>
              <input
                id="descricao-empresa"
                value={descricaoEmpresa}
                onChange={(e) => setDescricaoEmpresa(e.target.value)}
                className="w-full p-1 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
            </div>

            <div>
              <label htmlFor="imagem-capa" className="block text-md font-semibold text-gray-700 mb-1">
                Imagem de Capa:
              </label>
              <input type="file" accept="image/*" onChange={handleImageChange} className="w-full file:mr-2 file:py-2 file:px-4 file:border-0 file:bg-blue-50 file:text-blue-700 rounded-lg" />
            </div>

            <div className="md:col-span-2 lg:col-span-3">
              <label htmlFor="endereco-empresa" className="block text-md font-semibold text-gray-700 mb-1">
                Endereço da Empresa:
              </label>
              <GpsAddressPicker
                onCodUserInstance={parametros.codUserInstance}
                initialAddress={parametros.endereco || ''}
                initialNumero={numero}
                initialComplemento={complemento}
                onAddressChange={handleAddressEmpresaChange}
                onNumeroChange={handleNumeroEmpresaChange}
                onComplementoChange={handleComplementoEmpresaChange}
                onCoordinatesChange={handleStoreCoordinatesChange}
                radius={radius}
                isStore={true}

              />
            </div>
            {/* <div>
              <label htmlFor="secondary-color-picker" className="block text-md font-semibold text-gray-700 mb-1">
                Moeda:
              </label>
              <select
                id="select-currency"
                value={currency}
                onChange={(e) => { setCurrency(e.target.value); console.log(e.target.value) }}
                className="w-full p-1 border border-gray-300 rounded-md focus:ring focus:ring-blue-300"
              >
                <option value="">Selecione uma moeda</option>
                {currencies.map((currency: any) => (
                  <option key={currency.code} id={currency.symbol} value={currency.code}>
                    {currency.name} ({currency.symbol})
                  </option>
                ))}
              </select>
            </div> */}
            <div>
              <label htmlFor="secondary-color-picker" className="block text-md font-semibold text-gray-700 mb-1">
                Limite do raio de cobertura para frete :
              </label>
              <input
                type="number"
                id="radius"
                value={radius}
                onChange={(e) => setRadius(Number(e.target.value))}
                className="border rounded px-3 py-1 mb-4 w-full"
                min="0"
              />

            </div>
            <div>
              <div>
                <label htmlFor="valor-produto" className="block text-md font-semibold text-gray-700 mb-1">
                  Valor por raio de cobertura para frete:
                </label>
                <div className="flex items-center gap-2">
                  <input
                    type="number"
                    value={valorRadius}
                    onChange={(e) => setValorRadius(parseFloat(e.target.value) || 0)}
                    className="rounded-md p-1 border border-gray-300"
                    min={0}
                    placeholder="Enter amount"
                  />  <p className="text-md font-semibold mt-1">
                    {formatCurrency(valorRadius, currency)}
                  </p>
                </div>

              </div>
            </div>
            <div>
            </div>
            <div>
              <label htmlFor="color-picker" className="block text-md font-semibold text-gray-700 mb-1">
                Escolha a Cor de Fundo:
              </label>
              <input
                id="color-picker"
                type="color"
                value={colorBackground}
                onChange={(e) => setColorBackground(e.target.value)}
                className="w-full h-10 p-1 border border-gray-300 rounded-lg cursor-pointer"
              />
            </div>

            <div>
              <label htmlFor="primary-color-picker" className="block text-md font-semibold text-gray-700 mb-1">
                Cor Primária do Texto:
              </label>
              <input
                id="primary-color-picker"
                type="color"
                value={colorPrimary}
                onChange={(e) => setColorPrimary(e.target.value)}
                className="w-full h-10 p-1 border border-gray-300 rounded-lg cursor-pointer"
              />
            </div>

            <div>
              <label htmlFor="secondary-color-picker" className="block text-md font-semibold text-gray-700 mb-1">
                Cor Secundária do Texto:
              </label>
              <input
                id="secondary-color-picker"
                type="color"
                value={colorSecondary}
                onChange={(e) => setColorSecondary(e.target.value)}
                className="w-full h-10 p-1 border border-gray-300 rounded-lg cursor-pointer"
              />
            </div>

            <div className="md:col-span-2 lg:col-span-3 flex gap-4">
              <button
                onClick={handleSubmit}
                className="mt-2 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              >
                Salvar
              </button>
            </div>
            <div>
              <label htmlFor="select-category" className="block text-md font-semibold text-gray-700 mb-1">
                Selecione uma Categoria:
              </label>
              <select
                id="select-category"
                value={selectedCategory}
                onChange={(e) => { setSelectedCategory(e.target.value) }}
                className="w-full p-1 border border-gray-300 rounded-md focus:ring focus:ring-blue-300"
              >
                <option value="">Adicionar nova categoria</option>
                {cardapio.map((categoria: any) => (
                  <option key={categoria.id} value={categoria.id}>
                    {categoria.nome}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label htmlFor="new-category" className="block text-md font-semibold text-gray-700 mb-1">
                Nome da Categoria:
              </label>
              <input
                id="new-category"
                type="text"
                value={newCategory}
                onChange={(e) => setNewCategory(e.target.value)}
                className="w-full p-1 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
            </div>

            <div>
              <label htmlFor="new-category-description" className="block text-md font-semibold text-gray-700 mb-1">
                Descrição da Categoria:
              </label>
              <input
                id="new-category-description"
                value={newCategoryDescription}
                onChange={(e) => setNewCategoryDescription(e.target.value)}
                className="w-full p-1 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
            </div>
            <div className="relative inline-flex col-6 select-none ml-1 pl-4">
              <div className="relative inline-block w-16 select-none">

                <small className='text-center whitespace-nowrap'>Indisponivel / Disponivel</small>
                {storeAtivoCategoria === 1 ?
                  <>
                    <input
                      type="checkbox"
                      name="toggle"
                      id="storeAtivo"
                      title="Fechar Cardapio Virtual"
                      checked
                      onClick={() => { setStoreAtivoCategoria(0); }}
                      className={`checked:bg-green-500 outline-none focus:outline-none checked:right-0 duration-200 ease-in absolute block w-8 h-8 rounded-full bg-white border-4 appearance-none cursor-pointer`}
                    />
                    <label
                      htmlFor="storeAtivo"
                      className="block h-8 overflow-hidden bg-gray-300 rounded-full cursor-pointer"
                    >
                    </label>
                  </>
                  :
                  <>
                    <input
                      type="checkbox"
                      name="toggle"
                      title="Abrir Cardapio Virtual"
                      id="storeAtivo"
                      onClick={() => { setStoreAtivoCategoria(1); }}
                      className={`checked:bg-green-500 outline-none focus:outline-none checked:right-0 duration-200 ease-in absolute block w-8 h-8 rounded-full bg-white border-4 appearance-none cursor-pointer`}
                    />
                    <label
                      htmlFor="storeAtivo"
                      className="block h-8 overflow-hidden bg-gray-300 rounded-full cursor-pointer"
                    ></label>

                  </>
                }

              </div>
            </div>

            <div className="md:col-span-2 lg:col-span-3 flex gap-4">
              <button
                onClick={handleAddOrEditCategory}
                className="mt-2 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              >
                {selectedCategory ? 'Editar Categoria' : 'Adicionar Categoria'}
              </button>
              {selectedCategory ?
                <button
                  type="button"
                  onClick={() => { handleDeleteCategory(selectedCategory) }}
                  className="mt-2 bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 focus:ring-2 focus:ring-red-500 focus:outline-none"
                >
                  Remover Categoria
                </button> :
                <></>}
            </div>
            {selectedCategory && (
              <>
                <div>
                  <label htmlFor="select-produto" className="block text-md font-medium text-gray-700 mb-1">
                    Selecione um Produto:
                  </label>
                  <select
                    id="select-produto"
                    value={selectedProduto}
                    onChange={(e) => { setSelectedProduto(e.target.value); setFileProduto(null); }}
                    className="w-full p-1 border border-gray-300 rounded-md focus:ring focus:ring-blue-300"
                  >
                    <option value="">Adicionar novo produto</option>
                    {cardapio.map((categoria: any) =>
                      categoria.produtos &&
                      categoria.produtos
                        .filter((produto: any) => produto.codStoreCategoria === Number(selectedCategory))
                        .map((produto: any) => (
                          <option key={produto.id} value={produto.id}>
                            {produto.nome}
                          </option>
                        ))
                    )}
                  </select>
                </div>

                <div>
                  <label htmlFor="new-produto" className="block text-md font-medium text-gray-700 mb-1">
                    Nome do Produto:
                  </label>
                  <input
                    id="new-produto"
                    type="text"
                    value={newProduto}
                    onChange={(e) => setNewProduto(e.target.value)}
                    className="w-full p-1 border border-gray-300 rounded-md focus:ring focus:ring-blue-300"
                  />
                </div>

                <div>
                  <label htmlFor="new-produto-description" className="block text-md font-medium text-gray-700 mb-1">
                    Descrição do Produto:
                  </label>
                  <input
                    id="new-produto-description"
                    value={newProdutoDescription}
                    onChange={(e) => setNewProdutoDescription(e.target.value)}
                    className="w-full p-1 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
                  />
                </div>

                <div>
                  <label htmlFor="imagem-produto" className="block text-md font-semibold text-gray-700 mb-1">
                    Imagem do Produto:
                  </label>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageProdutoChange}
                    className="w-full file:mr-4 file:py-2 file:px-4 file:border-0 file:bg-blue-50 file:text-blue-700 rounded-lg"
                  />
                </div>

                <div>
                  <label htmlFor="valor-produto" className="block text-md font-semibold text-gray-700 mb-1">
                    Valor do Produto:
                  </label>
                  <div className="flex items-center gap-2">
                    <input
                      type="number"
                      value={valorProduto}
                      onChange={(e) => setValorProduto(parseFloat(e.target.value) || 0)}
                      className="rounded-md p-1 border border-gray-300"
                      min={0}
                      placeholder="Enter amount"
                    />  <p className="text-md font-semibold mt-1">
                      {formatCurrency(valorProduto, currency)}
                    </p>
                  </div>

                </div>
                <div className="relative inline-flex col-6 select-none ml-1 pl-4">
                  <div className="relative inline-block w-16 select-none">

                    <small className='text-center whitespace-nowrap'>Indisponivel / Disponivel</small>
                    {storeAtivoProduto === 1 ?
                      <>
                        <input
                          type="checkbox"
                          name="toggle"
                          id="storeAtivo"
                          title="Fechar Cardapio Virtual"
                          checked
                          onClick={() => { setStoreAtivoProduto(0); }}
                          className={`checked:bg-green-500 outline-none focus:outline-none checked:right-0 duration-200 ease-in absolute block w-8 h-8 rounded-full bg-white border-4 appearance-none cursor-pointer`}
                        />
                        <label
                          htmlFor="storeAtivo"
                          className="block h-8 overflow-hidden bg-gray-300 rounded-full cursor-pointer"
                        >
                        </label>
                      </>
                      :
                      <>
                        <input
                          type="checkbox"
                          name="toggle"
                          title="Abrir Cardapio Virtual"
                          id="storeAtivo"
                          onClick={() => { setStoreAtivoProduto(1); }}
                          className={`checked:bg-green-500 outline-none focus:outline-none checked:right-0 duration-200 ease-in absolute block w-8 h-8 rounded-full bg-white border-4 appearance-none cursor-pointer`}
                        />
                        <label
                          htmlFor="storeAtivo"
                          className="block h-8 overflow-hidden bg-gray-300 rounded-full cursor-pointer"
                        ></label>

                      </>
                    }

                  </div>
                </div>
                <div className="md:col-span-2 lg:col-span-3 flex gap-4 mt-2">
                  <button
                    type="button"
                    onClick={handleAddOrEditProduto}
                    className="mt-2 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                  >
                    {selectedProduto !== '' ? 'Editar Produto' : 'Adicionar Produto'}
                  </button>
                  {selectedProduto !== '' ?
                    <button
                      type="button"
                      onClick={handleDeleteProduto}
                      className="mt-2 bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 focus:ring-2 focus:ring-red-500 focus:outline-none"
                    >
                      Remover Produto
                    </button>
                    :
                    <></>}
                </div>
              </>
            )}
          </section>
        </div>

      )
        :
        (<></>)}


      <section id="produtos" aria-labelledby="produtos-title" style={{ backgroundColor: colorBackground }} className="py-2 sm:py-24 lg:py-12 m-2">
        <ImageClipPaths id={id} />
        <div>
          {imageUrl &&
          
        <DraggableImage imageUrl={imageUrl} backgroundStyle={backgroundStyle} />
          /*   <div
              className="w-full h-56 sm:h-64 md:h-80 lg:h-96 p-10 bg-contain bg-center bg-no-repeat"
              style={{
                backgroundOrigin: 'content-box',
                backgroundImage: `url('${imageUrl}')`,
                backgroundSize: backgroundStyle.size,
                backgroundPosition: backgroundStyle.position,
                backgroundRepeat: backgroundStyle.repeat,
                filter: backgroundStyle.filter,
                borderRadius: backgroundStyle.borderRadius,
                boxShadow: backgroundStyle.boxShadow,
              }}
            >
            </div> */}
          <div
            className="w-full text-center lg:mt-6">
            <h2
              className="font-display justify-center block text-3xl sm:text-4xl lg:text-5xl font-semibold mt-1"
              style={{ color: colorPrimary }}
            >
              {empresa}
            </h2>
            <p className="mt-2 text-md justify-center sm:text-xl mb-2" style={{ color: colorSecondary }}>
              {descricaoEmpresa}
            </p></div>
          <TabGroup className="mt-1 sm:mt-1 lg:mt-1 grid gap-4 grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-5 m-4" vertical={tabOrientation === 'vertical'}>

            <div className="relative justify-center flex overflow-x-auto pb-4 sm:mx-0 sm:block sm:overflow-visible sm:pb-0">

              {/* <div className="absolute bottom-0 left-0.5 top-2 hidden w-px bg-slate-200 lg:block" /> */}
              <TabList className=" grid-cols-1 lg:grid-cols lg:grid-cols-1 lg:text-left sm:grid-cols-1 sm:text-center">
                {({ selectedIndex }) => (
                  <>
                    {cardapio.length > 0 && cardapio.map((categorias: any, categoriasIndex) => (
                      <div key={categorias.id} className="relative lg:pl-8">
                        {/* <DiamondIcon
                          className={clsx(
                            'absolute left-[-0.5px] top-[0.5625rem] hidden h-1.5 w-1.5 lg:block',
                            categoriasIndex === selectedIndex ? 'fill-blue-600 stroke-blue-600' : 'fill-transparent stroke-slate-400'
                          )}
                        /> */}
                        <div className="relative text-center">
                          <div className={clsx(
                            'font-mono text-xl',
                            categoriasIndex === selectedIndex ? 'text-blue-600' : 'text-slate-500'
                          )}
                          >
                            <Tab className="focus-visible:outline-none font-semibold">
                              <span className="absolute inset-0" />

                              <span className="mt-3 block text-xl font-semibold" style={{ color: colorPrimary }}>
                                {categorias.nome}
                              </span>
                            </Tab>
                          </div>
                          <span className="block text-md font-semibold" style={{ color: colorSecondary }}>{categorias.descricao}
                          </span>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </TabList>
            </div>
            <TabPanels className="lg:col-span-4">
              {cardapio.map((p: any) => (
                <TabPanel
                  key={p.id}
                  className="grid grid-cols-1 gap-2 sm:grid-cols-1 lg:grid-cols-3"
                  unmount={false}
                >
                  {p.produtos.map((prod: any, i: any) => (
                    <>
                      {prod.ativo ?
                        <div className="p-2">
                          <div className="group relative h-48 items-center justify-center overflow-hidden rounded-xl">
                            <img
                              className="absolute inset-0 h-full w-full object-cover transition-transform duration-500 group-hover:scale-105 group-hover:brightness-110"
                              src={prod.imagem ? 'https://api.chatbr.xyz/uploads/produto/' + prod.imagem : ''}
                              alt={prod.nome}
                            />
                            <button
                              className="absolute bottom-4 right-4 w-14 h-14 flex items-center justify-center text-white bg-green-600 rounded-full hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                              aria-label="Adicionar mais produtos" onClick={() => handleAddProdutoCarrinho(prod)}
                            >
                              <span className="text-3xl font-bold text-center items-center justify-center mb-1">+</span>
                            </button>
                          </div>
                          <div className="text-center">
                            <h3 className="text-xl font-bold" style={{ color: colorPrimary }}>{prod.nome}</h3>
                            <p className="text-base" style={{ color: colorSecondary }}>{prod.descricao}</p>
                            <small className="block text-base" style={{ color: colorPrimary }}>{formatCurrency(Number(prod.valor), currency)}</small>
                          </div>
                        </div> : <></>}</>))}
                </TabPanel>
              ))}
            </TabPanels>

            {addressEmpresa ? <> <Carrinho parametros={atualizaCarrinho} onCarrinhoChange={handleCarrinhoChange} /></> : <></>}
          </TabGroup>

          
        </div>
      </section>
    </div >
  )
}
import api from "./Api";
import { Subject } from 'rxjs';
import Cookies from 'universal-cookie';
import moment from 'moment';
import UserService from './UserApi';
import { finished } from "stream";

const subject = new Subject();
const cookies = new Cookies();
//voltou ao normal
const login = (email: string, password: string) => {
    return api.post("/login", {
        email,
        password,
    }).then((response : any) => {
        console.log(response)
        if (response.data.token) {
            cookies.set('user', JSON.stringify(response.data.user), { path: '/', expires : moment().add(1, 'day').toDate() }); 
            cookies.set('instancias', JSON.stringify(response.data.instances), { path: '/', expires : moment().add(1, 'day').toDate() }); 
            cookies.set('atendentes', JSON.stringify(response.data.atendentes), { path: '/', expires : moment().add(1, 'day').toDate() }); 
            cookies.set('token', JSON.stringify(response.data.token), { path: '/', expires : moment().add(1, 'day').toDate() });
            /* cookies.set('user', JSON.stringify(response.data.user), { path: '/', expires : moment().add(30, 'day').toDate() }); 
            cookies.set('token', JSON.stringify(response.data.token), { path: '/', expires : moment().add(30, 'day').toDate() }); */
            observable.setToken(response.data.token)
        }
       /*  else
        {
            console.log(response);
        } */
        return response.data;
    });
};

const logout = () => {

    
    api.post("/logout", {
    }).then((response : any) => {
        console.log(response);
        
        cookies.remove('user');
        cookies.remove('token');
        cookies.remove('atendentes');
        cookies.remove('instancias');
        observable.clearToken()
        return true;
    });
  
};

const getToken = () => {
    let user = cookies.get('token');
    if(user !== undefined){
        return user;
    }
    return null;
};

const register = async (nome: string, dataNascimento: string, email: string, password: string, pais: string, cpf: any, cnpj: any): Promise<any> => {
  
      const response = await api.post("/register", { nome, dataNascimento, email, password, pais, cpf, cnpj});
    
        if(!response.data.error) {
            cookies.set('user', JSON.stringify(response.data.original.user), { path: '/', expires : moment().add(1, 'day').toDate() });
            cookies.set('token', JSON.stringify(response.data.original.token), { path: '/', expires : moment().add(1, 'day').toDate() });
            observable.setToken(response.data.original.token)

            return response.data.original; 
        }
        else
        {
            return response;
        }      
    
   
  };

  const registerAtendente = async (nome: string, dataNascimento: string, email: string, password: string, pais: string, cpf: any, cnpj: any): Promise<any> => {
    const response = await api.post("/register/atendente", { nome, dataNascimento, email, password, pais, cpf, cnpj });
    console.log(response.data);
    cookies.set('atendentes', JSON.stringify(response.data['AllUsers']), { path: '/', expires : moment().add(1, 'day').toDate() }); 
    return response.data;
  };
  


const callApiUrl = (url: string, body: any) => {
    return api.get(url, {params: body}).then((response : any) => {
        return response;
    });
};


const observable = {
    setToken: (token:any) => subject.next(token),
    clearToken: () => subject.next(null),
    onToken: () => subject.asObservable()
};




export default {
    login,
    logout,
    getToken,
    callApiUrl,
    register,
    registerAtendente,
    observable

};

import api from "./Api";

interface BotConfigParams {
    paginate?: number;
    filters?: Record<string , any>;
    orderBy?: Record<string, 'asc' | 'desc'>;
}

const listBotConfigs = (params: BotConfigParams = {}) => {
    var paginate = {} as any;
    var filters = null; // Definindo como null por padrão
    var orderBy = null; // Definindo como null por padrão

    if (params.paginate) {
        paginate = params.paginate;
    } else {
        paginate = "";
    }

    if (params.filters) {
        // Se houver filtros, os formatamos corretamente
        filters = params.filters;
    } else {
        filters = null;
    }

    if (params.orderBy) {
        orderBy = params.orderBy;
    }

    return api.get('/chatpro/botconfig', {
        params: {
            paginate,
            filters, // Espalha os filtros aqui para enviar como parte dos parâmetros
            orderBy
        }
    })
    .then(response => response.data);
};



const showBotConfig = (id: any) => {
    return api.get(`/chatpro/botconfig/${id}`)
        .then(response => response.data);
};

const getFilaEspera = (params: BotConfigParams = {}) => {
    var paginate = {} as any;
    var filters = null; // Definindo como null por padrão
    var orderBy = null; // Definindo como null por padrão

    if (params.paginate) {
        paginate = params.paginate;
    } else {
        paginate = "";
    }

    if (params.filters) {
        // Se houver filtros, os formatamos corretamente
        filters = params.filters;
    } else {
        filters = null;
    }

    if (params.orderBy) {
        orderBy = params.orderBy;
    }
    return api.get(`/fila/espera`, {
        params: {
            paginate,
            filters, 
            orderBy
        }
    }).then(response => response.data );
};

const createBotConfig = ( data : any ) => {
  
    return api.post("/chatpro/botconfig", data )
        .then(response => response.data);
};

const updateBotConfig = (id: any, botConfigData: any) => {
    return api.put(`/chatpro/botconfig/${id}`, botConfigData)
        .then(response => response.data);
};

const deleteBotConfig = (id: any) => {
    return api.delete(`/chatpro/botconfig/${id}`)
        .then(response => response.data);
};

const createCategoriaAtendente = (data : any) => {
    return api.post(`/categorias/atendente`, data)
        .then(response => response.data);
};

const deleteCategoriaAtendente = (id: any) => {
    return api.delete(`/categorias/atendente/${id}`)
        .then(response => response.data);
};

const listCategoriaAtendente = (params: BotConfigParams = {}) => {
    var paginate = {} as any;
    var filters = null; // Definindo como null por padrão
    var orderBy = null; // Definindo como null por padrão

    if (params.paginate) {
        paginate = params.paginate;
    } else {
        paginate = "";
    }

    if (params.filters) {
        // Se houver filtros, os formatamos corretamente
        filters = params.filters;
    } else {
        filters = null;
    }

    if (params.orderBy) {
        orderBy = params.orderBy;
    }

    return api.get(`/categorias/atendente`, {
        params: {
            paginate,
            filters, 
            orderBy
        }
    }).then(response => response.data);
};


const updateCategoriaAtendente = (id: any, categoriaAtendenteData: any) => {
    console.log(id, categoriaAtendenteData)
    return api.put(`/categorias/atendente/${id}`, categoriaAtendenteData)
        .then(response => response.data);
};

const createAtendenteAlocado = (data : any) => {
    return api.post(`/atendente/alocados`, data)
        .then(response => response.data);
};

const removeDepartamentoAlocado = (id : any) => {
    return api.delete(`/atendente/alocados/${id}`)
        .then(response => response.data);
};

const listAtendenteAlocado = (params: BotConfigParams = {}) => {
    var paginate = {} as any;
    var filters = null; // Definindo como null por padrão
    var orderBy = null; // Definindo como null por padrão

    if (params.paginate) {
        paginate = params.paginate;
    } else {
        paginate = "";
    }

    if (params.filters) {
        // Se houver filtros, os formatamos corretamente
        filters = params.filters;
    } else {
        filters = null;
    }

    if (params.orderBy) {
        orderBy = params.orderBy;
    }

    return api.get(`/atendente/alocados`, {
        params: {
            paginate,
            filters, 
            orderBy
        }
    }).then(response => response.data);
};

export default {
    listBotConfigs,
    showBotConfig,
    createBotConfig,
    updateBotConfig,
    deleteBotConfig,
    createCategoriaAtendente,
    listCategoriaAtendente,
    updateCategoriaAtendente,
    deleteCategoriaAtendente,
    createAtendenteAlocado,
    listAtendenteAlocado,
    getFilaEspera,
    removeDepartamentoAlocado
};

import React, { useState } from 'react';

interface DraggableImageProps {
  imageUrl: string;
  backgroundStyle: {
    filter?: string;
    borderRadius?: string;
    boxShadow?: string;
  };
}

const DraggableImage: React.FC<DraggableImageProps> = ({ imageUrl, backgroundStyle }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
  const [currentPosition, setCurrentPosition] = useState({ x: 50, y: 50 }); // Inicia no centro
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    setIsDragging(true);
    setStartPosition({ x: e.clientX, y: e.clientY });
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isDragging) return;
    const deltaX = e.clientX - startPosition.x;
    const deltaY = e.clientY - startPosition.y;

    setCurrentPosition((prev) => ({
      x: Math.max(0, Math.min(100, prev.x + deltaX / 5)), // Limita entre 0 e 100%
      y: Math.max(0, Math.min(100, prev.y + deltaY / 5)), // Ajusta sensibilidade
    }));

    setStartPosition({ x: e.clientX, y: e.clientY });
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <div
      className="relative w-full h-56 sm:h-64 md:h-80 lg:h-96 bg-no-repeat"
      style={{
        backgroundImage: `url('${imageUrl}')`,
        backgroundSize: 'cover',
        backgroundPosition: `${currentPosition.x}% ${currentPosition.y}%`,
        filter: backgroundStyle.filter || 'none',
        borderRadius: backgroundStyle.borderRadius || '0px',
        boxShadow: backgroundStyle.boxShadow || 'none',
      }}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseOut={() => setShowTooltip(false)}
    >
      {/* Tooltip */}
      {showTooltip && (
        <div className="absolute -top-10 left-1/2 transform -translate-x-1/2 bg-black text-white text-xs px-2 py-1 rounded shadow-lg">
          Clique e arraste para mover a imagem
          <div className="absolute bottom-[-6px] left-1/2 transform -translate-x-1/2 w-3 h-3 bg-black rotate-45"></div>
        </div>
      )}
    </div>
  );
};

export default DraggableImage;

import React, { useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './views/App';
import reportWebVitals from './reportWebVitals';
/* import Cookies from "universal-cookie"; */
import NavBar from './components/Navbar';
import SignIn from './views/Auth/SignIn';
import SignUp from './views/Auth/SignUp';
import Payment from './views/Auth/Payment';
import Home from './views/Plans/Basic/Home';
import Pricing from './views/Auth/Pricing';
import Dashboard from './views/Dashboard';
import Profile from './views/Users/ProfileSettings';
import AdminList from './views/Users/AdminList';
import UsersList from './views/Users/UsersList';
import PlansList from './views/Plans/PlansList';
import NotificationList from './views/Users/NotificationList';
import Kanban from './views/Plans/Basic/kanban';
import Modal from './components/Modal/Modal';
import Auth from './services/Auth';
import Modals from './components/Modal/Modal';
import Loading from './components/Utils/Loading';
import Cookies from 'universal-cookie';
import { Store } from './views/Plans/Basic/Store';
import StoreApi from './services/StoreApi';

/* const cookies = new Cookies(); */
const rootElement = document.getElementById('root');
const cookies = new Cookies();

function LoadingBar({ ...rest }) {

  const [loading, setLoading] = useState(false);
  const [modalLoadShow, setModalLoadShow] = useState(false);
  const cookies = new Cookies();


  useEffect(() => {
    // subscribe to home component messages
    const subscription = Loading.onLoading().subscribe((loading: any) => {
      if (loading) {
        setLoading(loading);
        setModalLoadShow(true)
      } else {
        setLoading(false);
        setModalLoadShow(false)
      }
    });

    return subscription.unsubscribe;
  }, []);
  return (
    <>

      <Modal
        show={loading}
        size={"small"}
        fullscreen={false}
        list={
          <>
            <div className='text-center text-dark'>
              <div className="spinner-border text-primary" role="status">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={50}
                  height={50}
                  viewBox="0 0 100 100"
                  fill="none"
                >
                  <circle
                    cx={50}
                    cy={50}
                    r={45}
                    stroke="#25D366"
                    strokeWidth={10}
                    opacity="0.2"
                  />
                  <circle
                    cx={50}
                    cy={50}
                    r={45}
                    stroke="#25D366"
                    strokeWidth={10}
                    strokeDasharray={283}
                    strokeDashoffset={75}
                  >
                    <animateTransform
                      attributeName="transform"
                      type="rotate"
                      from="0 50 50"
                      to="360 50 50"
                      dur="1s"
                      repeatCount="indefinite"
                    />
                  </circle>
                </svg>

              </div>

            </div>
          </>
        }
        footer={
          <>
            <div className='text-end end-div mb-2 text-dark'>
              <small className='mb-4'>Wait...</small>
            </div>
          </>
        }
      />


    </>
  )
}
if (rootElement !== null) {
  createRoot(rootElement).render(
    <BrowserRouter>
      <LoadingBar />
      <div className='wrapper h-screen w-screen' style={{ background: "#dad3cc" }}>
        <NavBar />
        <div className='main' style={{ background: "#dad3cc" }}>
          <PrivateRoute />
        </div>
      </div>
    </BrowserRouter>
  );
}

function PrivateRoute() {
  const [token, setToken] = useState(Auth.getToken());
  const [user, setUser] = useState(cookies.get('user'));
  const [userRole, setUserRole] = useState(cookies.get('user') !== undefined ? cookies.get('user').roles?.[0]?.name : '');
  const [userPermission, setUserPermission] = useState(cookies.get('user') !== undefined ? cookies.get('user').permissions?.[0]?.name : '');
  const [dynamicRoutes, setDynamicRoutes] = useState<JSX.Element[]>([]);
  const [routesLoaded, setRoutesLoaded] = useState(false); // Rastreamento do carregamento de rotas
  const navigate = useNavigate();

  useEffect(() => {
    const subscription = Auth.observable.onToken().subscribe((token: any) => {
      setToken(token || null);
    });

    const fetchDynamicRoutes = async () => {
      try {
        const response = await StoreApi.listStoreEmpresas();
        const routesData = response.data;

        console.log('Rotas recebidas:', routesData);

        const routes = routesData.map((route: any) => {
          if (!route.id || !route.url) {
            console.warn('Rota inválida ignorada:', route);
            return null;
          }
          return <Route key={route.id} path={`/${route.url}`} element={<Store parametros={route} />} />;
        });

        setDynamicRoutes(routes.filter(Boolean));
      } catch (error) {
        console.error('Erro ao buscar rotas dinâmicas:', error);
      } finally {
        setRoutesLoaded(true); // Marca como carregado
      }
    };

    fetchDynamicRoutes();

    return () => subscription.unsubscribe();
  }, []);

  if (!routesLoaded) {
    // Tela de carregamento enquanto as rotas dinâmicas estão sendo carregadas
    return <div></div>;
  }

  if (token === null) {
    return (
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/sign-up" element={<SignUp />} />
        {dynamicRoutes}
        <Route path="*" element={<Navigate to="/sign-in" />} />
      </Routes>
    );
  }

    if (userRole === "cliente" || userRole === "atendente") {
      switch (userPermission) {
        case "Visitante":
          return (
            <Routes>
              <Route path="/sign-in" element={<SignIn />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/pricing-plan" element={<Payment />} />
              {dynamicRoutes}
              <Route path="*" element={<Navigate to="/pricing" />} />
            </Routes>
          );
        case "Plano Teste":
        case "Plano Basic":
          return (
            <Routes>
              <Route path="/home" element={<Home />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/notification-list" element={<NotificationList />} />
              <Route path="/profile-settings" element={<Profile />} />
              {dynamicRoutes}
              <Route path="*" element={<Navigate to="/home" />} />
            </Routes>
          );
        default:
          return <Navigate to="/sign-up" />;
      }
    } else if (userRole === "admin" || userRole === "master") {
      return (
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/profile-settings" element={<Profile />} />
          <Route path="/kanban" element={<Kanban />} />
          <Route path="/adm-list" element={<AdminList />} />
          <Route path="/user-list" element={<UsersList />} />
          <Route path="/plans-list" element={<PlansList />} />
          <Route path="/notification-list" element={<NotificationList />} />
          {dynamicRoutes}
          <Route path="*" element={<Navigate to="/home" />} />
        </Routes>
      );
    } else {
      return (
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/sign-up" element={<SignUp />} />
          {dynamicRoutes}
          <Route path="*" element={<Navigate to="/sign-in" />} />
        </Routes>
      );
    }
}


reportWebVitals();
import React, { useEffect, useRef, useState, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';

import moment from 'moment';
import chatProApi from '../../../services/ChatProApi';
import chatConfigApi from '../../../services/ChatConfigApi';
import Modal from '../../../components/Modal/Modal';
import Cookies from 'universal-cookie';
import EmojiPicker from '../../../components/Utils/EmojiPicker';
import UserApi from '../../../services/UserApi';
import AuthService from "../../../services/Auth";
import BotConfig from './BotConfig';
import { MultiSelectComboBox, MultiSelectComboBoxChangeEvent } from '@hilla/react-components/MultiSelectComboBox';
import { Store } from './Store';
import { BoltIcon, ChatBubbleLeftRightIcon, FunnelIcon, HomeIcon, LinkIcon, ShoppingCartIcon } from '@heroicons/react/24/solid';

function Home() {
 
  let navigate = useNavigate();
  const cookies = new Cookies();
  const [showModal, setShowModal] = useState(false);
  const [showModalAddUser, setShowModalAddUser] = useState(false);
  const [showDisconnect, setShowDisconnect] = useState(false);
  const [autoBot, setAutoBot] = useState(false);
  const [base64, setBase64] = useState("" as any);
  const [status, setStatus] = useState(false);
  const [modalConfig, setModalConfig] = useState(false);
  const [infoInstance, setInfoInstance] = useState([{}] as any);
  const [atendenteSelecionado, setAtendenteSelecionado] = useState({} as any);
  const [instanciaSelecionada, setInstanciaSelecionada] = useState({} as any);
  const [steps, setSteps] = useState([] as any);
  const [filaEspera, setFilaEspera] = useState([] as any);
  var [instancia, setInstancia] = useState([0]);
  var [mensagemResponse, setMensagemResponse] = useState("");
  var [mensagemResponseOriginal, setMensagemResponseOriginal] = useState("");
  var [mensagemsInicio, setMensagemInicio] = useState("");
  var [mensagemsFim, setMensagemFim] = useState("");
  var [mensagemErro, setMensagemErro] = useState("");
  var [imgInstancia1, setImgInstancia1] = useState("");
  const instancesCliente = cookies.get('user')?.instances_cliente;

  const [items, setItems] = useState([] as any);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [selectedInstanceItems, setSelectedInstanceItems] = React.useState([]);
  const userInstance = cookies.get('instancias');
  const people = [
    {
      name: 'Lindsay Walton',
      entrega: 'Retirada na loja',
      telefone: '+55321233123321',
      role: 'Member',
      image:
        'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    {
      name: 'Lindsay Walton2',
      entrega: 'Entrega',
      telefone: '+55321233123321',
      role: 'Member2',
      image:
        'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    {
      name: 'Lindsay Walton2',
      entrega: 'Entrega',
      telefone: '+55321233123321',
      role: 'Member2',
      image:
        'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    {
      name: 'Lindsay Walton2',
      entrega: 'Retirada na loja',
      department: 'Optimization2',
      telefone: '+55321233123321',
      role: 'Member2',
      image:
        'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    {
      name: 'Lindsay Walton2',
      entrega: 'Retirada na loja',
      telefone: '+55321233123321',
      role: 'Member2',
      image:
        'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    }
  ]

  const contatosPorCategoria: { [key: string]: number } =
    userInstance && userInstance.length > 0 && userInstance.codUserInstance !== 0
      ? userInstance[0]?.categoria_atendente?.reduce((acc: any, categoria: any) => {
        if (filaEspera !== undefined && filaEspera !== null && categoria.id !== undefined && categoria.id !== 0) {
          acc[categoria.id] = filaEspera?.filter((fila: any) => fila.codCategoria === categoria.id).length;
          return acc;
        }

      }, {})
      : {};

  const contatosPorCategoriaAtendente: { [key: string]: number } =
    instancesCliente && instancesCliente.length > 0 && instancesCliente.codUserInstance !== 0
      ? instancesCliente[0]?.categoria_atendente?.reduce((acc: any, categoria: any) => {
        if (filaEspera !== undefined && filaEspera !== null) {
          acc[categoria.id] = filaEspera?.filter((fila: any) => fila.codCategoria === categoria.id).length;
          return acc;
        }
      }, {})
      : {};

  const [showModalAtendentes, setShowModalAtendentes] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlert] = useState("");
  const [number, setNumber] = useState(null);
  const [editing, setEditing] = useState<'response' | 'inicio' | 'fim'>('response');

  const [Contatos, setContatos] = useState([] as any);
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  var [validPassword, setValidPassword] = useState(0);
  var [validEmail, setValidEmail] = useState(false);
  var [passwordStrength, setPasswordStrength] = useState(0);

  const [ImgPerfil, setImgPerfil] = useState("");
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [SelectedContact, setSelectedContact] = useState(0);
  const [SelectedContactImage, setSelectedContactImage] = useState("");
  const [SelectedContactName, setSelectedContactName] = useState("");
  const [Conversas, setConversas] = useState({} as any);

  const clickClose = () => {
    setShowModal(false);
    chatProApi.getStatus().then((status: any) => {
      if (status?.data.response.connected === true) {
        setInfoInstance([status.data.response]);
        setStatus(true);
        setBase64("");
      }
    })
  }

  const handleToggle = (event: any) => {
    const targetId = event.currentTarget.getAttribute('data-target');
    const submenu = document.getElementById(targetId);
    const icon = event.currentTarget.querySelector('.menu-icon');

    // Toggle submenu visibility
    if (submenu !== null) {
      submenu.classList.toggle('max-h-0');
      submenu.classList.toggle('max-h-screen');
      submenu.classList.toggle('overflow-hidden');
    }

    // Toggle icon rotation
    icon.classList.toggle('rotate-180');
  };

  const wrapText = (text: string, maxLineLength: number): string => {
    let wrappedText = '';
    let currentLineLength = 0;

    for (let i = 0; i < text.length; i++) {
      if (currentLineLength >= maxLineLength && text[i] !== '\n') {
        wrappedText += '\n';
        currentLineLength = 0;
      }
      wrappedText += text[i];
      currentLineLength++;

      if (text[i] === '\n') {
        currentLineLength = 0;
      }
    }

    return wrappedText;
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const originalText = event.target.value;
    console.log(originalText);
    setMensagemResponseOriginal(originalText);
    const wrappedText = wrapText(originalText, 32);
    setMensagemResponse(wrappedText);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      if (editing === 'response') {
        setMensagemResponse(prevMensagem => {
          const newMessage = prevMensagem + '\n';
          return wrapText(newMessage, 32);
        });
      } else if (editing === 'inicio') {
        setMensagemInicio(prevMensagem => {
          const newMessage = prevMensagem + '\n';
          return wrapText(newMessage, 32);
        });
      } else if (editing === 'fim') {
        setMensagemFim(prevMensagem => {
          const newMessage = prevMensagem + '\n';
          return wrapText(newMessage, 32);
        });
      }
    }
  };

  const renderConversation = (conversation: any) => {
    return conversation.split('\n\n').map((text: any, index: any, array: any) => (
      <React.Fragment key={index}>
        <p className="text-sm mt-1">{text}</p>
        {index < array.length - 1 && <br />}
      </React.Fragment>
    ));
  }

  const alocarAtendentes = () => {
    const atendenteId = atendenteSelecionado.id; // ID do atendente selecionado
    const instanciasAlocadas = selectedInstanceItems; // instâncias já alocadas
    const departamentosAlocados = selectedItems; // departamentos já alocados

    // Obter todas as instâncias atualmente alocadas para o atendente
    const instanciasJaAlocadas = cookies.get('instancias')[0].atendente_alocados.filter((i: any) => i.codAtendente === atendenteId);

    // Obter instâncias já alocadas pelo atendente
    const instanciasJaAlocadasIds = instanciasJaAlocadas.map((i: any) => i.codUserInstance);

    // Filtrar instâncias novas que não estão alocadas
    const novasInstancias = instanciasAlocadas.filter((instancia: any) => !instanciasJaAlocadasIds.includes(instancia.id));

    // Obter departamentos que precisam ser adicionados
    const novosDepartamentos = departamentosAlocados.filter((departamento: any) => {
      return !instanciasJaAlocadas.some((i: any) => i.idCategoria === departamento.id);
    });

    // Preparar os dados para a chamada da API
    const dadosParaAdicionar = {
      atendenteId: atendenteId,
      departamentos: novosDepartamentos,
    };

    const instanciasParaRemover = instanciasJaAlocadas.filter((i: any) => !instanciasAlocadas.some((inst: any) => inst.id === i.codUserInstance));

    const departamentosParaRemover = instanciasJaAlocadas.filter((i: any) => {
      return !departamentosAlocados.some((dep: any) => dep.id === i.idCategoria);
    });

    const dadosParaRemover = {
      instancias: instanciasParaRemover.map((instancia: any) => ({
        id: instancia.id
      })),
      departamentos: departamentosParaRemover.map((departamento: any) => ({
        id: departamento.id
      }))
    };

    console.log("Dados para adicionar:", dadosParaAdicionar);
    if (novosDepartamentos.length > 0) {
      novosDepartamentos.map((d: any) => {
        chatConfigApi.createAtendenteAlocado({ 'codAtendente': atendenteId, 'codUserInstance': d.codUserInstance, 'idCategoria': d.id }).then((r) => {
          cookies.set('user', JSON.stringify(r.original.user), { path: '/', expires: moment().add(1, 'day').toDate() });
          cookies.set('instancias', JSON.stringify(r.original.instances), { path: '/', expires: moment().add(1, 'day').toDate() });
          cookies.set('atendentes', JSON.stringify(r.original.atendentes), { path: '/', expires: moment().add(1, 'day').toDate() });
        });

      })
    }

    if (dadosParaRemover.instancias.length > 0) {
      dadosParaRemover.instancias.forEach((instancia: any) => {
        chatConfigApi.removeDepartamentoAlocado(instancia.id).then((r) => {
          cookies.set('user', JSON.stringify(r.original.user), { path: '/', expires: moment().add(1, 'day').toDate() });
          cookies.set('instancias', JSON.stringify(r.original.instances), { path: '/', expires: moment().add(1, 'day').toDate() });
          cookies.set('atendentes', JSON.stringify(r.original.atendentes), { path: '/', expires: moment().add(1, 'day').toDate() });
        });
      });
    }

    if (dadosParaRemover.departamentos.length > 0) {
      dadosParaRemover.departamentos.forEach((departamento: any) => {
        chatConfigApi.removeDepartamentoAlocado(departamento.id).then((r) => {
          cookies.set('user', JSON.stringify(r.original.user), { path: '/', expires: moment().add(1, 'day').toDate() });
          cookies.set('instancias', JSON.stringify(r.original.instances), { path: '/', expires: moment().add(1, 'day').toDate() });
          cookies.set('atendentes', JSON.stringify(r.original.atendentes), { path: '/', expires: moment().add(1, 'day').toDate() });
        });
      });
    }

  };

  const handleAutoBot = (id: number) => {

    UserApi.updateUserInstanceActive(id).then((user: any) => {
      cookies.set('user', user.data);
      window.location.reload();
    })
  }

  const formatLastMessageTime = (unixTimestamp: any) => {
    const date = new Date(unixTimestamp * 1000); // Convertendo de segundos para milissegundos
    const options: Intl.DateTimeFormatOptions = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
    };
    return date.toLocaleString('pt-BR', options);
};


  const getStrengthBarColor = (index: number) => {
    if (index < passwordStrength) return 'bg-green-500';
    return 'bg-gray-200';
  };

  const handlePasswordChange = (e: any) => {

    setPassword(e.target.value)

    let strength = 0;
    if (e.target.value.length > 4)
      strength++;
    if (e.target.value.match(/[a-z]+/))
      strength++;
    if (e.target.value.match(/[A-Z]+/))
      strength++;
    if (e.target.value.match(/[0-9]+/))
      strength++;
    if (e.target.value.match(/[^a-zA-Z0-9]+/))
      strength++;

    if (strength > 3) {
      setValidPassword(1)
    }
    else if (strength <= 3)
      setValidPassword(0)
    else
      setValidPassword(0)

    setPasswordStrength(strength);


  };

  const handleDisconnect = () => {
    setShowDisconnect(false);
    setStatus(false);
    chatProApi.removeSession().then((response: any) => {
      window.location.reload();
    })


  };

  useEffect(() => {
    if (base64 === "") {
      chatProApi.getStatus().then((status: any) => {
        if (status.data.response !== undefined) {

          if (status.data.response.code === 400) {

            chatProApi.QrCodeChatPro().then((qr: any) => {
              if (qr !== undefined) {
                if (qr.data.response['qr'] === "") {
                  chatProApi.reload().then((response) => {
                    chatProApi.QrCodeChatPro().then((qr) => {
                      if (qr.data.response['qr'] !== "") {
                        setBase64(qr.data.response['qr']);
                      }
                    })
                  })
                }
                else {
                  setBase64(qr.data.response['qr']);
                }

              }
            })

          }
          else if (status.data.response?.connected === true) {
            setInfoInstance([status.data.response]);
            chatProApi.getProfile({ 'number': status.data.response.info.Wid.split('@')[0] }).then((r) => {
              if (r !== undefined && r?.data?.response['imgUrl'] !== undefined) {
                setImgInstancia1(r?.data?.response['imgUrl']);
              }
            })
            setStatus(true);
            setBase64("");
          }
        }
        if (status.data.response?.connected) {
          var phone = status.data.response.info['Wid'].split('@');
          chatProApi.getProfile({ 'number': phone[0] }).then((r) => {
            setImgPerfil(r?.data?.response['imgUrl']);
            chatProApi.listChats().then((contatos: any) => {
              setContatos(contatos.data.response);
              setSelectedContact(contatos.data.response[0]['jid'].split('@')[0]);
              chatProApi.getProfile({ 'number': contatos.data.response[0]['jid'].split('@')[0] }).then((r: any) => {
                setNumber(status.data.response.info.Wid.split('@')[0]);
                setSelectedContactName(r.data.response?.name);
                setSelectedContactImage(r.data.response?.imgUrl);
              })

              if (cookies.get('user')?.roles[0]?.name === 'atendente') {
                UserApi.showUser(cookies.get('user').instances_cliente[0].codUserCliente).then((user: any) => {
                  setConversas(user.data.user_instance[0].user_instance_web_hook);
                  setInstancia([user.data.user_instance[0].id]);
                })
              }
              else {
                if (cookies.get('user') !== undefined) {
                  UserApi.showUser(cookies.get('user')?.id).then((user: any) => {
                    setConversas(user.data.user_instance[0].user_instance_web_hook);
                  })
                }

              }

            })
          })

        }
      });

    }

    if (cookies.get('user')?.roles[0]?.name !== 'atendente') {
      setItems(cookies.get('user')?.user_instance[0]?.categoria_atendente);
      chatConfigApi.getFilaEspera({
        "filters": {
          "codUserInstance": instancia[0]
        }
      }).then((response) => {
        setFilaEspera(response);
      });

    }
    else {
      setItems(cookies.get('user')?.instances_cliente[0]?.categoria_atendente);
      chatConfigApi.getFilaEspera({
        "filters": {
          "codUserInstance": cookies.get('user')?.instances_cliente[0].id
        }
      }).then((response) => {
        setFilaEspera(response);
        console.log(response);
      });
    }

    chatConfigApi.listBotConfigs({
      "filters": {
        "codUserInstance": instancia
      }
    }).then((response) => {
      setSteps(response);
    });

    const intervalo = setInterval(() => {
      if (base64 === "") {
        chatProApi.getStatus().then((status: any) => {

          if (status?.data?.response !== undefined) {

            if (status.data?.response?.connected === true) {
              setInfoInstance([status.data.response]);
              setStatus(true);
              setBase64("");
            }
            else {
              chatProApi.QrCodeChatPro().then((qr) => {

                if (qr.data.response['qr'] === "") {
                  chatProApi.reload().then((response) => {
                    chatProApi.QrCodeChatPro().then((qr) => {
                      if (qr.data.response['qr'] !== "") {
                        setBase64(qr.data.response['qr']);
                      }
                    })
                  })
                }
                else {
                  setBase64(qr.data.response['qr']);
                }
              })
            }
          }
          else {
            navigate("sign-in");
          }
        })
      }

      if (cookies.get('user') !== undefined) {
        chatProApi.getStatus().then((status: any) => {
          if (status !== undefined) {

            if (status.data?.response?.connected === true) {

              if (status.data?.response?.connected) {
                var phone = status.data.response.info['Wid'].split('@');
                chatProApi.getProfile({ 'number': phone[0] }).then((r) => {
                  console.log(r?.data?.response);
                  if (r?.data?.response !== undefined) {
                    setImgPerfil(r?.data?.response['imgUrl']);
                  }
                  chatProApi.listChats().then((contatos: any) => {
                    setContatos(contatos.data?.response);
                    setSelectedContact(contatos.data.response[0]['jid'].split('@')[0]);
                    chatProApi.getProfile({ 'number': contatos.data?.response[0]['jid'].split('@')[0] }).then((r: any) => {
                      setNumber(status.data.response.info.Wid.split('@')[0]);
                      if (r.data?.response?.name !== undefined) {
                        setSelectedContactName(r?.data.response?.name);
                      }
                      if (r?.data?.response?.imgUrl !== undefined)
                        setSelectedContactImage(r?.data.response?.imgUrl);
                    })

                    if (cookies.get('user')?.roles[0]?.name === 'atendente') {
                      console.log('atendente');
                      UserApi.showUser(cookies.get('user').instances_cliente[0].codUserCliente).then((user: any) => {
                        if (user.data.user_instance !== undefined || user.data.user_instance.length > 0)
                          setConversas(user.data.user_instance[0]?.user_instance_web_hook);
                      })
                    }
                    else {
                      if (cookies.get('user') !== undefined)
                        UserApi.showUser(cookies.get('user').id).then((user: any) => {

                          setConversas(user.data.user_instance[0]?.user_instance_web_hook);
                        })
                    }

                  })
                })

              }
            }

          }
        })
      }


    }, 90 * 1000);

    return () => clearInterval(intervalo);
  }, []);

  const onEmojiSelectResponse = (emoji: any) => {
    setMensagemResponse(mensagemResponse + emoji);
  };

  const handleDateEmail = (e: any) => {
    setEmail(e.target.value);

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

    if (emailRegex.test(email)) {
      console.log("Email válido");
      setValidEmail(true);
    } else {
      console.log("Email inválido");
      setValidEmail(false);
    }
  };

  const register = (idInstance: any) => {
    console.log(idInstance.id);
    if (passwordStrength <= 3) {

      setShowAlert(true);
      setAlert("Heads up! Your password is like a paper fortress – easy to breach. Please choose a stronger combo for your security shield.");
    }
    else if (!validEmail) {

      setShowAlert(true);
      setAlert("Whoops! This email seems to be from another dimension. Please enter a valid email address to connect to our world.");
    }
    else if (nome === "") {

      setShowAlert(true);
      setAlert("Name is Empty");
    }
    else {
      setShowAlert(true);

      AuthService.registerAtendente(nome, selectedDate, email, password, selectedCountry, null, null).then(
        (r) => {
          setAlert("User created.");
          UserApi.showUsersAtendentesAll().then((atendentes: any) => {
            console.log(atendentes);
            cookies.set('atendentes', atendentes.data.AllUsers, { path: '/', expires: moment().add(1, 'day').toDate() });

            setNome('');
            setEmail('');
            setPassword('');
            setPasswordConfirmation('');
            setShowModalAddUser(false);
          })

        }
      );
    }


  };

  const handleSelectNumber = (number: any) => {
    chatProApi.getProfile({ 'number': number['jid'].split('@')[0] }).then((r: any) => {
      setNumber(number['jid'].split('@')[0]);
      console.log(r.data.response)
      setSelectedContactName(r.data.response?.name);
      setSelectedContactImage(r.data.response?.imgUrl);
    })
    setSelectedContact(number['jid'].split('@')[0]);
    setShowChat(true);
    const bottomScroll = document.getElementById("scrollableDiv");
    if (bottomScroll) {
      bottomScroll.scrollTop = bottomScroll.scrollHeight;
    }

  }

  const handleSelectionChange = (event: any) => {
    setSelectedItems(event.target.selectedItems);
  };

  const handleSelectionInstanceChange = (event: any) => {
    setSelectedInstanceItems(event.target.selectedItems);

  };

  return (
    <>
      <div className="w-full h-auto">
        <div className="h-8 xml:h-28 bg-green-500" />

        <div className="flex flex-col bg-gray-50 sm:flex-row border border-grey rounded shadow-lg mb-10 m-4 h-auto">

          <div className="w-full bg-gray-50 border-md sm:border-md flex flex-col">

            <div className="py-2 bg-gray-50 px-4 bg-grey-lighter flex flex-col w-full">


              <div className="flex flex-wrap md:m-2">

                <div className="w-full w-sm:w-1/1 md:w-1/2 lg:w-1/2 xl:w-1/4 p-1">
                  <div className="d-block p-4 max-w bg-white rounded-lg border shadow-md sm:p-4 dark:bg-gray-800 dark:border-gray-700 h-full  overflow-auto" style={{ maxHeight: '400px' }}>

                    {cookies.get('user') !== undefined &&
                      cookies.get('user').roles[0]?.name !== 'atendente' ?
                      <>
                        <div className="flex items-center mb-2">

                          <HomeIcon aria-hidden="true"
                        className="h-6 w-6 text-gray-400 hover:text-gray-500"/>

                          <div className="ml-4 text-xl">Meu Plano</div>
                        </div>

                        <div className="flex whitespace-nowrap items-center justify-between mb-4 space-x-2">
                          <span className="flex items-center px-1 py-1 text-xs font-semibold text-gray-500 bg-gray-200 rounded-md">
                            Plano Básico
                          </span>
                          <span className="flex items-center px-1 py-1 text-xs font-semibold text-green-400 bg-white border border-green-400 rounded-md">Assine o Plano Master</span>
                        </div>

                      </>
                      :
                      <></>
                    }
                    {cookies.get('user') !== undefined && cookies.get('user').roles[0]?.name !== 'atendente' ?
                      <>  <div className="ml-2 text-xl">Atendentes Cadastrados</div>
                        <ul
                          className="mt-1 px-2 transition-max-height duration-300"
                        >
                          <div className="flex items-center justify-start my-4 space-x-4">
                            {cookies.get('user').roles[0]?.name === 'cliente' ?
                              <>
                                {(cookies.get('user').permissions[0]?.name === "Plano Teste" || cookies.get('user').permissions[0]?.name === "Plano Basic") && cookies.get('atendentes')?.length < 3 ?
                                  <button onClick={() => { showModalAddUser ? setShowModalAddUser(false) : setShowModalAddUser(true); }} className="flex items-center px-2 py-1 text-xs white font-semibold text-gray-500 hover:bg-gray-100 rounded-md bg-green-50">
                                    Adicionar Atendente
                                  </button>
                                  :
                                  <></>
                                }
                                {/*  <button disabled className="flex items-center px-2 py-1 text-xs font-semibold text-gray-500 bg-green-50 hover:bg-gray-100 rounded-md">
                                PLAN UPGRADE
                              </button> */}
                                <button onClick={() => {
                                  UserApi.showUsersAtendentesAll().then((atendentes: any) => {
                                    console.log(atendentes);
                                    cookies.set('atendentes', atendentes.data.AllUsers, { path: '/', expires: moment().add(1, 'day').toDate() });
                                  })
                                }}
                                  className="flex items-center px-2 py-1 text-xs font-semibold text-gray-500 hover:bg-gray-100 rounded-md bg-green-50">
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16">
                                    <path fill="none" d="M0 0h24v24H0z" />
                                    <path fill="currentColor" d="M12 4V1L8 5l4 4V6c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6h-2c0 4.42 3.58 8 8 8s8-3.58 8-8-3.58-8-8-8z" />
                                  </svg> Atualizar
                                </button>
                              </>
                              :
                              <>
                              </>

                            }
                          </div>

                          {cookies.get('atendentes') !== undefined && cookies.get('atendentes').length > 0 &&
                            cookies.get("atendentes").map((r: any, key: number) => {

                              return (
                                <li key={r['id']} className="py-3 sm:py-1">
                                  <div className="flex items-center space-x-4">
                                    <div className="flex-shrink-0">
                                      <img className="inline-block h-10 w-10 rounded-full object-cover ring-2 ring-white" src="./Anoni.jpg" alt="User Avatar" />
                                      <span className={`block h-3 w-3 rounded-full ring-2 ring-white ${r.online ? 'bg-green-500' : 'bg-red-500'}`}></span>
                                    </div>
                                    <div className="flex-1 min-w-0">
                                      <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                                        {r.nome}
                                      </p>
                                      <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                        {r.email}
                                      </p>
                                    </div>
                                    <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                      <button onClick={() => {
                                        setShowModalAtendentes(true);
                                        setAtendenteSelecionado(r);
                                        console.log(r);
                                        const instanciasAlocado = [] as any;
                                        const departamentosAlocado = [] as any;

                                        cookies.get('instancias')[0].atendente_alocados.map((i: any) => {
                                          if (i.codAtendente === r.id) {
                                            const instanciaCorrespondente = cookies.get('instancias').find((inst: any) => inst.id === i.codUserInstance);

                                            // Verifica se a instância já está no array antes de adicionar
                                            if (!instanciasAlocado.some((inst: any) => inst.id === instanciaCorrespondente.id)) {
                                              instanciasAlocado.push(instanciaCorrespondente);
                                            }

                                            cookies.get('instancias')[0].categoria_atendente.map((c: any) => {
                                              if (i.idCategoria === c.id) {
                                                // Verifica se o departamento já está no array antes de adicionar
                                                if (!departamentosAlocado.some((dep: any) => dep.id === c.id)) {
                                                  departamentosAlocado.push(c);
                                                }
                                              }
                                            });
                                          }
                                        });
                                        setSelectedInstanceItems(instanciasAlocado);
                                        setSelectedItems(departamentosAlocado);

                                        /*  cookies.get('instancias')[0].atendente_alocados.map((i: any) => {
                                          
                                           if(i.codAtendente === r.id)
                                           {
                                             
 
                                           }
                                         }); */
                                      }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" className="h-5 w-5 text-green-gray-500">
                                          <path fill-rule="evenodd" d="M11.078 2.25c-.917 0-1.699.663-1.85 1.567L9.05 4.889c-.02.12-.115.26-.297.348a7.493 7.493 0 00-.986.57c-.166.115-.334.126-.45.083L6.3 5.508a1.875 1.875 0 00-2.282.819l-.922 1.597a1.875 1.875 0 00.432 2.385l.84.692c.095.078.17.229.154.43a7.598 7.598 0 000 1.139c.015.2-.059.352-.153.43l-.841.692a1.875 1.875 0 00-.432 2.385l.922 1.597a1.875 1.875 0 002.282.818l1.019-.382c.115-.043.283-.031.45.082.312.214.641.405.985.57.182.088.277.228.297.35l.178 1.071c.151.904.933 1.567 1.85 1.567h1.844c.916 0 1.699-.663 1.85-1.567l.178-1.072c.02-.12.114-.26.297-.349.344-.165.673-.356.985-.57.167-.114.335-.125.45-.082l1.02.382a1.875 1.875 0 002.28-.819l.923-1.597a1.875 1.875 0 00-.432-2.385l-.84-.692c-.095-.078-.17-.229-.154-.43a7.614 7.614 0 000-1.139c-.016-.2.059-.352.153-.43l.84-.692c.708-.582.891-1.59.433-2.385l-.922-1.597a1.875 1.875 0 00-2.282-.818l-1.02.382c-.114.043-.282.031-.449-.083a7.49 7.49 0 00-.985-.57c-.183-.087-.277-.227-.297-.348l-.179-1.072a1.875 1.875 0 00-1.85-1.567h-1.843zM12 15.75a3.75 3.75 0 100-7.5 3.75 3.75 0 000 7.5z" clipRule="evenodd" />
                                        </svg>
                                      </button>
                                    </div>
                                  </div>
                                </li>

                              );
                            })
                          }
                        </ul>

                        {/* <div className="flow-root">
                        <ul role="list" className="divide-y divide-gray-200 dark:divide-gray-700 p-2 block" style={{ maxHeight: '240px', overflowY: 'auto' }}>
                          {cookies.get('user') !== undefined &&
                            cookies.get('user').roles[0]?.name !== 'atendente' ?
                            cookies.get("atendentes").map((r: any, key: number) => {
                              return (<>
                                <li className="py-3 sm:py-1">
                                  <div className="flex items-center space-x-4">
                                    <div className="flex-shrink-0">
                                      <img className="inline-block h-10 w-10 rounded-full object-cover ring-2 ring-white" src="./Anoni.jpg" alt="User Avatar" />
                                      <span className={`block h-3 w-3 rounded-full ring-2 ring-white ${r.online ? 'bg-green-500' : 'bg-red-500'}`}></span>
                                    </div>
                                    <div className="flex-1 min-w-0">
                                      <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                                        {r.user?.nome}
                                      </p>
                                      <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                        {r.user?.email}
                                      </p>
                                    </div>
                                    <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                      <button onClick={() => { setShowModalAtendentes(true); setAtendenteSelecionado(r); }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" className="h-5 w-5 text-green-gray-500">
                                          <path fill-rule="evenodd" d="M11.078 2.25c-.917 0-1.699.663-1.85 1.567L9.05 4.889c-.02.12-.115.26-.297.348a7.493 7.493 0 00-.986.57c-.166.115-.334.126-.45.083L6.3 5.508a1.875 1.875 0 00-2.282.819l-.922 1.597a1.875 1.875 0 00.432 2.385l.84.692c.095.078.17.229.154.43a7.598 7.598 0 000 1.139c.015.2-.059.352-.153.43l-.841.692a1.875 1.875 0 00-.432 2.385l.922 1.597a1.875 1.875 0 002.282.818l1.019-.382c.115-.043.283-.031.45.082.312.214.641.405.985.57.182.088.277.228.297.35l.178 1.071c.151.904.933 1.567 1.85 1.567h1.844c.916 0 1.699-.663 1.85-1.567l.178-1.072c.02-.12.114-.26.297-.349.344-.165.673-.356.985-.57.167-.114.335-.125.45-.082l1.02.382a1.875 1.875 0 002.28-.819l.923-1.597a1.875 1.875 0 00-.432-2.385l-.84-.692c-.095-.078-.17-.229-.154-.43a7.614 7.614 0 000-1.139c-.016-.2.059-.352.153-.43l.84-.692c.708-.582.891-1.59.433-2.385l-.922-1.597a1.875 1.875 0 00-2.282-.818l-1.02.382c-.114.043-.282.031-.449-.083a7.49 7.49 0 00-.985-.57c-.183-.087-.277-.227-.297-.348l-.179-1.072a1.875 1.875 0 00-1.85-1.567h-1.843zM12 15.75a3.75 3.75 0 100-7.5 3.75 3.75 0 000 7.5z" clipRule="evenodd" />
                                        </svg>
                                      </button>
                                    </div>
                                  </div>
                                </li>
                              </>)
                            })

                            : <></>
                          }
                        </ul>
                      </div> */}
                      </> :
                      <>
                        <div className="flex items-center mb-2">
                          <FunnelIcon aria-hidden="true"
                          className="h-6 w-6 text-gray-400 hover:text-gray-500"/>
                          <div className="ml-4 text-xl">Fila de Atendimento</div>
                        </div>
                        <div className="flow-root">
                          <ul role="list" className="-mx-2 space-y-1">
                            <li>
                              <div>
                                <button
                                  type="button"
                                  className="flex w-full items-center gap-x-3 rounded-md p-2 text-left text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50"
                                  aria-controls="menu-1-atendimento"
                                  aria-expanded="true"
                                  data-target="menu-1-atendimento"
                                  onClick={handleToggle}
                                >
                                  {infoInstance[0].info?.Pushname}
                                  <svg
                                    className="ml-auto h-5 w-5 shrink-0 text-gray-400 transition-transform duration-300 menu-icon"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                </button>
                                <ul
                                  className="mt-1 px-2 max-h-0 overflow-hidden transition-max-height duration-300 ease-in-out"
                                  id="menu-1-atendimento"
                                >
                                  {cookies.get('instancias')[0]?.categoria_atendente?.length > 0 &&
                                    cookies.get('instancias')[0]?.categoria_atendente.map((categoria: any, key: any) => {
                                      return (
                                        <li key={key}>
                                          <button
                                            type="button"
                                            className="flex w-full items-center gap-x-3 rounded-md p-2 text-left text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50"
                                            aria-controls={`sub-menu-${key}-atendimento`}
                                            aria-expanded="false"
                                            data-target={`sub-menu-${key}-atendimento`}
                                            onClick={handleToggle}
                                          >{console.log(categoria)}
                                            {categoria.setor}
                                            <span className="transform -translate-y-2/2 -translate-x-2/2">
                                              <div className="block w-4 h-4 mt-2 bg-green-400 rounded-full flex items-center justify-center text-white text-center text-sm">
                                                {categoria.id !== undefined ? contatosPorCategoriaAtendente[categoria.id] : <></>}
                                              </div>
                                            </span>
                                            <svg
                                              className="ml-auto h-5 w-5 shrink-0 text-gray-400 transition-transform duration-300 menu-icon"
                                              viewBox="0 0 20 20"
                                              fill="currentColor"
                                              aria-hidden="true"
                                            >
                                              <path
                                                fillRule="evenodd"
                                                d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                                clipRule="evenodd"
                                              />
                                            </svg>
                                          </button>
                                          <ul
                                            className="mt-1 px-2 max-h-0 overflow-hidden transition-max-height duration-300 ease-in-out"
                                            id={`sub-menu-${key}-atendimento`}
                                          >
                                            {filaEspera.map((fila: any) =>
                                              Contatos.map((r: any) => {
                                                if (
                                                  r['jid']?.split('@')[0] === fila.telefoneCliente &&
                                                  categoria.id === fila.codCategoria
                                                ) {
                                                  return (
                                                    <li key={r['jid']} className="py-3 sm:py-1">
                                                      <div className="flex items-center space-x-4">
                                                        <div className="flex-shrink-0">
                                                          {r?.imgUrl !== undefined ?
                                                            <img
                                                              className="h-12 w-12 rounded-full"
                                                              src={r?.imgUrl || "./Anoni.jpg"}
                                                              alt="Avatar"
                                                            />
                                                            :
                                                            <img
                                                              className="h-12 w-12 rounded-full"
                                                              src={"./Anoni.jpg"}
                                                              alt="Avatar"
                                                            />}
                                                        </div>
                                                        <div className="flex-1 min-w-0">
                                                          <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                                                            {r?.name}
                                                          </p>
                                                          <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                                            {r.unread} unanswered messages.
                                                          </p>
                                                          <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                                            Last Message <br /> {formatLastMessageTime(r.lastMessageTime)}
                                                          </p>
                                                        </div>
                                                        <div
                                                          className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white cursor-pointer"
                                                          onClick={() => handleSelectNumber(r)}
                                                        >
                                                          <svg
                                                            width="20px"
                                                            height="20px"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                          >
                                                            <path
                                                              fillRule="evenodd"
                                                              clipRule="evenodd"
                                                              d="M12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 13.4811 3.09753 14.8788 3.7148 16.1181C3.96254 16.6155 4.05794 17.2103 3.90163 17.7945L3.30602 20.0205C3.19663 20.4293 3.57066 20.8034 3.97949 20.694L6.20553 20.0984C6.78973 19.9421 7.38451 20.0375 7.88191 20.2852C9.12121 20.9025 10.5189 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75ZM1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C10.2817 22.75 8.65552 22.3463 7.21315 21.6279C6.99791 21.5207 6.77814 21.4979 6.59324 21.5474L4.3672 22.143C2.84337 22.5507 1.44927 21.1566 1.857 19.6328L2.4526 17.4068C2.50208 17.2219 2.47933 17.0021 2.37213 16.7869C1.65371 15.3445 1.25 13.7183 1.25 12ZM7.25 10.5C7.25 10.0858 7.58579 9.75 8 9.75H16C16.4142 9.75 16.75 10.0858 16.75 10.5C16.75 10.9142 16.4142 11.25 16 11.25H8C7.58579 11.25 7.25 10.9142 7.25 10.5ZM7.25 14C7.25 13.5858 7.58579 13.25 8 13.25H13.5C13.9142 13.25 14.25 13.5858 14.25 14C14.25 14.4142 13.9142 14.75 13.5 14.75H8C7.58579 14.75 7.25 14.4142 7.25 14Z"
                                                              fill="#1C274C"
                                                            />
                                                          </svg>
                                                        </div>
                                                      </div>
                                                    </li>
                                                  );
                                                }
                                                return null; // Add return null for the case when conditions are not met
                                              })
                                            )}
                                          </ul>
                                        </li>
                                      );
                                    })}
                                </ul>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </>}
                  </div>


                </div>
                <div className="w-full sm:w-1/1 md:w-1/2 lg:w-1/2 xl:w-1/4 p-1">
                  <div className="p-4 max-w bg-white rounded-lg border shadow-md sm:p-4 dark:bg-gray-800 dark:border-gray-700 h-full" style={{ maxHeight: '400px' }} >

                    <div className="flex items-center mb-2">
                      <BoltIcon aria-hidden="true"
                        className="h-6 w-6 text-gray-400 hover:text-gray-500" />
                    {/*   <svg
                        width={15}
                        height={15}
                        className="ml-1"
                        fill="currentColor"
                        viewBox="0 0 384 512"
                      >
                        <path
                          d="M384 144c0-44.2-35.8-80-80-80s-80 35.8-80 80c0 36.4 24.3 67.1 57.5 76.8c-.6 16.1-4.2 28.5-11 36.9c-15.4 19.2-49.3 22.4-85.2 25.7c-28.2 2.6-57.4 5.4-81.3 16.9v-144c32.5-10.2 56-40.5 56-76.3c0-44.2-35.8-80-80-80S0 35.8 0 80c0 35.8 23.5 66.1 56 76.3v199.3C23.5 365.9 0 396.2 0 432c0 44.2 35.8 80 80 80s80-35.8 80-80c0-34-21.2-63.1-51.2-74.6c3.1-5.2 7.8-9.8 14.9-13.4c16.2-8.2 40.4-10.4 66.1-12.8c42.2-3.9 90-8.4 118.2-43.4c14-17.4 21.1-39.8 21.6-67.9c31.6-10.8 54.4-40.7 54.4-75.9zM80 64c8.8 0 16 7.2 16 16s-7.2 16-16 16s-16-7.2-16-16s7.2-16 16-16zm0 384c-8.8 0-16-7.2-16-16s7.2-16 16-16s16 7.2 16 16s-7.2 16-16 16zm224-320c8.8 0 16 7.2 16 16s-7.2 16-16 16s-16-7.2-16-16s7.2-16 16-16z"
                          fill="currentColor"
                        ></path>
                      </svg> */}
                      <div className="ml-4 text-xl">Instancias</div>
                    </div>

                    <ul style={{ maxHeight: '300px', overflowY: 'auto' }}>
                      {cookies.get('user') !== undefined &&
                        cookies.get('user').roles[0]?.name !== 'atendente' &&
                        <li className="flex items-center justify-between py-3 text-gray-600 border-b-2 border-gray-100 dark:text-gray-200 dark:border-gray-800">
                          <button onClick={() => {
                            setShowModal(true);
                            chatProApi.QrCodeChatPro().then((qr) => {
                              setBase64(qr.data.response['qr']);
                            })
                          }} className="btn text-center align-center hover:bg-green-100 rounded-md p-2">
                            <div className="flex items-center justify-center text-sm">
                              <div className="flex items-center justify-center w-13 h-13 rounded-full bg-green-50">
                                <svg
                                  className="w-6 h-6 text-deep-purple-accent-400"
                                  stroke="currentColor"
                                  viewBox="0 0 52 52"
                                >
                                  <polygon
                                    strokeWidth="3"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    fill="none"
                                    points="29 13 14 29 25 29 23 39 38 23 27 23"
                                  />
                                </svg>
                              </div>
                              <span className='row'> CONECTAR NOVA INSTANCIA </span>
                            </div></button>
                        </li>
                      }
                      {!status ?
                        <li className="flex items-center justify-between py-3 text-gray-600 border-b-2 border-gray-100 dark:text-gray-200 dark:border-gray-800">
                          <div className="flex items-center justify-start text-sm">
                            <span className="mx-4">1</span>
                            <span>Você não está conectado.</span>
                          </div>
                        </li>
                        :
                        <>
                          {cookies.get('user') !== undefined ?
                            cookies.get('user').roles[0]?.name !== 'atendente' ?
                              cookies.get('user').user_instance.map((r: any, key: number) => {

                                return (
                                  <>  <div className="ml-2 text-xl">Instancias Cadastradas</div>
                                    <li className="col-12 flex justify-between items-center py-2 text-gray-600 border-b border-gray-100 dark:text-gray-200 dark:border-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700 transition duration-200">

                                      <div className='row'>
                                        <div className="d-flex items-center">
                                          {/* <span className="mx-4">{key + 1} </span> */}
                                          <p className='text-xsm'>{key + 1} - {infoInstance[0].info.Pushname}</p>
                                          <div className='w-full'>
                                            <div className="relative inline-flex col-4 select-none pr-10">
                                              <div className="relative inline-block w-10 items-end select-none">

                                                <small className='text-center whitespace-nowrap'>Automação</small>
                                                {r.ativo === 1 ?
                                                  <>
                                                    <input
                                                      type="checkbox"
                                                      name="toggle"
                                                      id="Green"
                                                      title="Desactive / Active Bot"
                                                      checked
                                                      onClick={() => { setAutoBot(false); handleAutoBot(r.id); }}
                                                      className={`checked:bg-green-500 outline-none focus:outline-none right-4 checked:right-0 duration-200 ease-in absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer`}
                                                    />
                                                    <label
                                                      htmlFor="Green"
                                                      className="block h-6 overflow-hidden bg-gray-300 rounded-full cursor-pointer"
                                                    >
                                                    </label>
                                                  </>
                                                  :
                                                  <>
                                                    <input
                                                      type="checkbox"
                                                      name="toggle"
                                                      title="Desactive / Active Bot"
                                                      id="Green"
                                                      onClick={() => { setAutoBot(true); handleAutoBot(r.id); }}
                                                      className={`checked:bg-green-500 outline-none focus:outline-none right-4 checked:right-0 duration-200 ease-in absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer`}
                                                    />
                                                    <label
                                                      htmlFor="Green"
                                                      className="block h-6 overflow-hidden bg-gray-300 rounded-full cursor-pointer"
                                                    ></label>

                                                  </>
                                                }

                                              </div>
                                            </div>
                                            <div className="relative inline-flex col-4 select-none pr-8">
                                              <div className="relative inline-block w-10 items-center select-none">
                                                <small className='text-center whitespace-nowrap'>Configurar</small>
                                                <div className="row">
                                                  <button className="m-1" onClick={() => { setModalConfig(true); setInstancia([r.id]); }} >
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" className="h-5 w-5 text-green-gray-500">
                                                      <path fill-rule="evenodd" d="M11.078 2.25c-.917 0-1.699.663-1.85 1.567L9.05 4.889c-.02.12-.115.26-.297.348a7.493 7.493 0 00-.986.57c-.166.115-.334.126-.45.083L6.3 5.508a1.875 1.875 0 00-2.282.819l-.922 1.597a1.875 1.875 0 00.432 2.385l.84.692c.095.078.17.229.154.43a7.598 7.598 0 000 1.139c.015.2-.059.352-.153.43l-.841.692a1.875 1.875 0 00-.432 2.385l.922 1.597a1.875 1.875 0 002.282.818l1.019-.382c.115-.043.283-.031.45.082.312.214.641.405.985.57.182.088.277.228.297.35l.178 1.071c.151.904.933 1.567 1.85 1.567h1.844c.916 0 1.699-.663 1.85-1.567l.178-1.072c.02-.12.114-.26.297-.349.344-.165.673-.356.985-.57.167-.114.335-.125.45-.082l1.02.382a1.875 1.875 0 002.28-.819l.923-1.597a1.875 1.875 0 00-.432-2.385l-.84-.692c-.095-.078-.17-.229-.154-.43a7.614 7.614 0 000-1.139c-.016-.2.059-.352.153-.43l.84-.692c.708-.582.891-1.59.433-2.385l-.922-1.597a1.875 1.875 0 00-2.282-.818l-1.02.382c-.114.043-.282.031-.449-.083a7.49 7.49 0 00-.985-.57c-.183-.087-.277-.227-.297-.348l-.179-1.072a1.875 1.875 0 00-1.85-1.567h-1.843zM12 15.75a3.75 3.75 0 100-7.5 3.75 3.75 0 000 7.5z" clipRule="evenodd" />
                                                    </svg>
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="relative inline-flex col-4 select-none">
                                              <div className="relative inline-block w-10 items-center select-none">
                                                <small className='text-center whitespace-nowrap'>Desconectar</small>
                                                <div className="row">
                                                  <button className="m-1" onClick={() => { setShowDisconnect(true); setInstancia([r.id]); }} >
                                                    <svg className="h-5 w-5 text-red-400 " width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                      <path fill="currentColor" d="M21.7803 3.28033C22.0732 2.98744 22.0732 2.51256 21.7803 2.21967C21.4874 1.92678 21.0126 1.92678 20.7197 2.21967L18.7077 4.23161C17.0483 3.05263 14.7323 3.20693 13.2448 4.6945L12.1767 5.76252C11.4933 6.44594 11.4933 7.55398 12.1767 8.2374L15.7625 11.8232C16.446 12.5066 17.554 12.5066 18.2374 11.8232L19.3054 10.7552C20.793 9.26761 20.9473 6.9517 19.7684 5.29228L21.7803 3.28033ZM18.1945 5.75516L18.2173 5.77798L18.2197 5.78033L18.222 5.78267L18.2448 5.80542C19.3187 6.87936 19.3187 8.62056 18.2448 9.6945L17.1767 10.7625C17.0791 10.8602 16.9208 10.8602 16.8232 10.7625L13.2374 7.17674C13.1398 7.07911 13.1398 6.92082 13.2374 6.82318L14.3054 5.75516C15.3794 4.68122 17.1206 4.68122 18.1945 5.75516Z" />
                                                      <path fill="currentColor" d="M10.7803 11.2803C11.0732 10.9874 11.0732 10.5126 10.7803 10.2197C10.4874 9.92678 10.0126 9.92678 9.71967 10.2197L8.00001 11.9393L7.53035 11.4697C7.23746 11.1768 6.76258 11.1768 6.46969 11.4697L4.69456 13.2448C3.20701 14.7324 3.0527 17.0483 4.23163 18.7077L2.21967 20.7197C1.92678 21.0126 1.92678 21.4874 2.21967 21.7803C2.51256 22.0732 2.98744 22.0732 3.28033 21.7803L5.29229 19.7684C6.95171 20.9473 9.26766 20.793 10.7552 19.3055L12.5303 17.5303C12.8232 17.2374 12.8232 16.7626 12.5303 16.4697L12.0607 16L13.7803 14.2803C14.0732 13.9874 14.0732 13.5126 13.7803 13.2197C13.4874 12.9268 13.0126 12.9268 12.7197 13.2197L11 14.9393L9.06067 13L10.7803 11.2803ZM7.46631 13.527L7.46967 13.5303L7.47305 13.5337L10.4664 16.527L10.4697 16.5303L10.473 16.5336L10.9394 17L9.69456 18.2448C8.62062 19.3187 6.87942 19.3187 5.80548 18.2448L5.75522 18.1945C4.68128 17.1206 4.68128 15.3794 5.75522 14.3055L7.00002 13.0607L7.46631 13.527Z" />
                                                    </svg>
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  </>
                                );
                              })
                              :

                              cookies.get('user').instances_cliente.map((r: any, key: number) => {

                                return (
                                  <>
                                    <li className="flex justify-between py-3 text-gray-600 border-b-2 border-gray-100 dark:text-gray-200 dark:border-gray-800">
                                      <div className="flex items-end text-sm mx-4">
                                        <span className="mx-4">{key + 1} -</span>
                                        <span>{infoInstance[0].info.Pushname}</span>

                                      </div>
                                      <button onClick={() => { setModalConfig(true); setInstancia([r.id]); }} >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" className="h-5 w-5 text-green-gray-500">
                                          <path d="M11.078 2.25c-.917 0-1.699.663-1.85 1.567L9.05 4.889c-.02.12-.115.26-.297.348a7.493 7.493 0 00-.986.57c-.166.115-.334.126-.45.083L6.3 5.508a1.875 1.875 0 00-2.282.819l-.922 1.597a1.875 1.875 0 00.432 2.385l.84.692c.095.078.17.229.154.43a7.598 7.598 0 000 1.139c.015.2-.059.352-.153.43l-.841.692a1.875 1.875 0 00-.432 2.385l.922 1.597a1.875 1.875 0 002.282.818l1.019-.382c.115-.043.283-.031.45.082.312.214.641.405.985.57.182.088.277.228.297.35l.178 1.071c.151.904.933 1.567 1.85 1.567h1.844c.916 0 1.699-.663 1.85-1.567l.178-1.072c.02-.12.114-.26.297-.349.344-.165.673-.356.985-.57.167-.114.335-.125.45-.082l1.02.382a1.875 1.875 0 002.28-.819l.923-1.597a1.875 1.875 0 00-.432-2.385l-.84-.692c-.095-.078-.17-.229-.154-.43a7.614 7.614 0 000-1.139c-.016-.2.059-.352.153-.43l.84-.692c.708-.582.891-1.59.433-2.385l-.922-1.597a1.875 1.875 0 00-2.282-.818l-1.02.382c-.114.043-.282.031-.449-.083a7.49 7.49 0 00-.985-.57c-.183-.087-.277-.227-.297-.348l-.179-1.072a1.875 1.875 0 00-1.85-1.567h-1.843zM12 15.75a3.75 3.75 0 100-7.5 3.75 3.75 0 000 7.5z" clipRule="evenodd" />
                                        </svg>
                                      </button>
                                    </li>
                                  </>
                                );
                              })
                            :
                            <></>
                          }
                          {/*   <li className="flex justify-between py-3 text-gray-600 border-b-2 border-gray-100 dark:text-gray-200 dark:border-gray-800">
                            <div className="flex items-center justify-start text-sm">
                              <span className="mx-4"></span>
                              <span>Assine o Plano Plus e adicione novas instancias.</span>

                            </div>

                          </li> */}
                        </>
                      }
                    </ul>


                    <div className="flex -space-x-2 mt-6">

                      <img
                        className="inline-block h-10 w-10 rounded-full object-cover ring-2 ring-white"
                        src={imgInstancia1 !== "" ? imgInstancia1 : "./Anoni.jpg"}
                      />
                      {/*    <button onClick={() => navigate("/")} className="">
                        <img
                          className="inline-block h-10 w-10 rounded-full object-cover ring-2 ring-white"
                          src="/perfil.jpg"
                          alt="Max"
                        />
                      </button> */}

                    </div>

                  </div>

                </div>

                <div className="w-full sm:w-1/1 md:w-1/2 lg:w-1/2 xl:w-1/4 p-1">

                  <div className="d-block p-4 max-w bg-white rounded-lg border shadow-md sm:p-4 dark:bg-gray-800 dark:border-gray-700 h-full" style={{ maxHeight: '400px' }}>

                    <div className="flex items-center mb-2">
                      
                    <ChatBubbleLeftRightIcon aria-hidden="true"
                      className="h-6 w-6 text-gray-400 hover:text-gray-500" />
                      <div className="ml-4 text-xl">Últimas Conversas</div>
                    </div>
                    <div className="flow-root">
                      <ul role="list" className="divide-y divide-gray-200 dark:divide-gray-700 p-2 mr-0" style={{ maxHeight: '300px', overflowY: 'auto' }}>
                        {Contatos?.length === 0 ?
                          <li className="py-3 sm:py-1">Nenhuma Conversa Salva.</li>
                          :
                          Contatos.map((r: any) => {
                            if (r['jid']?.split('@')[0] !== "0") {
                              console.log(r);
                              return (
                                <>
                                  <li className="py-3 sm:py-1">
                                    <div className="flex items-center space-x-4 mr-4">
                                      <div className="flex-shrink-0">
                                        <img
                                          className="h-12 w-12 rounded-full"
                                          src={r?.imgUrl !== undefined ? r?.imgUrl : "./Anoni.jpg"}
                                          alt="Avatar"
                                        />
                                      </div>
                                      <div className="flex-1 min-w-0">
                                        <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                                          {r?.name}
                                        </p>
                                        <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                          {r.unread === 0
                                            ? "Nenhuma mensagem não lida"
                                            : r.unread === 1
                                              ? "1 mensagem não lida"
                                              : `${r.unread} mensagens não lidas`}
                                        </p>
                                        <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                          Última mensagem <br /> {formatLastMessageTime(r.lastMessageTime)}
                                        </p>
                                      </div>
                                      <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white cursor-pointer"
                                        onClick={() => { handleSelectNumber(r); }}>
                                        <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 13.4811 3.09753 14.8788 3.7148 16.1181C3.96254 16.6155 4.05794 17.2103 3.90163 17.7945L3.30602 20.0205C3.19663 20.4293 3.57066 20.8034 3.97949 20.694L6.20553 20.0984C6.78973 19.9421 7.38451 20.0375 7.88191 20.2852C9.12121 20.9025 10.5189 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75ZM1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C10.2817 22.75 8.65552 22.3463 7.21315 21.6279C6.99791 21.5207 6.77814 21.4979 6.59324 21.5474L4.3672 22.143C2.84337 22.5507 1.44927 21.1566 1.857 19.6328L2.4526 17.4068C2.50208 17.2219 2.47933 17.0021 2.37213 16.7869C1.65371 15.3445 1.25 13.7183 1.25 12ZM7.25 10.5C7.25 10.0858 7.58579 9.75 8 9.75H16C16.4142 9.75 16.75 10.0858 16.75 10.5C16.75 10.9142 16.4142 11.25 16 11.25H8C7.58579 11.25 7.25 10.9142 7.25 10.5ZM7.25 14C7.25 13.5858 7.58579 13.25 8 13.25H13.5C13.9142 13.25 14.25 13.5858 14.25 14C14.25 14.4142 13.9142 14.75 13.5 14.75H8C7.58579 14.75 7.25 14.4142 7.25 14Z" fill="#1C274C" />
                                        </svg>

                                      </div>
                                    </div>
                                  </li>

                                </>);


                            }
                          })}

                      </ul>
                    </div>
                  </div>
                </div>
                <div className="w-full p-1 xml:w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/4">
                  {cookies.get('user').roles[0]?.name === 'atendente' ?
                    <>

                    </>
                    :
                    <>
                      <div className="p-4 max-w bg-white rounded-lg border shadow-md sm:p-4 dark:bg-gray-800 dark:border-gray-700 h-full" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                        <div className="flex items-center mb-2">

                          <FunnelIcon aria-hidden="true"
                          className="h-6 w-6 text-gray-400 hover:text-gray-500"/>
                            <div className="ml-4 text-xl">Fila de Atendimento</div>
                        </div>
                        <div className="flow-root">
                          <ul role="list" className="-mx-2 space-y-1">
                            <li>
                              <div>
                                {infoInstance[0].info?.Pushname}
                                <ul
                                  className="mt-1 px-2 max-h-screen overflow-hidden transition-max-height duration-300 ease-in-out"
                                  id="menu-1"
                                >
                                  {cookies.get('instancias')[0]?.categoria_atendente?.length > 0 &&
                                    cookies.get('instancias')[0]?.categoria_atendente.map((categoria: any, key: any) => (
                                      <>
                                        <li key={key}>
                                          <button
                                            type="button"
                                            className="flex w-full items-center gap-x-3 rounded-md p-2 text-left text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50"
                                            aria-controls={`sub-menu-${key}`}
                                            aria-expanded="true"
                                            data-target={`sub-menu-${key}`}
                                            onClick={handleToggle}
                                          >
                                            {categoria.setor}
                                            <span className="transform -translate-y-2/2 -translate-x-2/2"> <div className="flex w-4 h-4 mt-2 bg-green-400 rounded-full items-center justify-center text-white text-center text-md"> {categoria.id !== undefined ? contatosPorCategoria[categoria.id] : <></>}</div></span>
                                            <svg
                                              className="ml-auto h-5 w-5 shrink-0 text-gray-400 transition-transform duration-300 menu-icon"
                                              viewBox="0 0 20 20"
                                              fill="currentColor"
                                              aria-hidden="true"
                                            >
                                              <path
                                                fillRule="evenodd"
                                                d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                                clipRule="evenodd"
                                              />
                                            </svg>
                                          </button>
                                          <ul
                                            className="mt-1 px-2 max-h-0 overflow-hidden transition-max-height duration-300 ease-in-out"
                                            id={`sub-menu-${key}`}
                                          >
                                            {filaEspera.map((fila: any) =>
                                              Contatos.map((r: any) => {
                                                if (r['jid']?.split('@')[0] === fila.telefoneCliente && categoria.id === fila.codCategoria) {

                                                  return (
                                                    <>
                                                      <li key={r['jid']} className="py-3 sm:py-1">
                                                        <div className="flex items-center space-x-4">
                                                          <div className="flex-shrink-0">
                                                            <img
                                                              className="h-12 w-12 rounded-full"
                                                              src={r?.imgUrl !== undefined ? r?.imgUrl : "./Anoni.jpg"}
                                                              alt="Avatar"
                                                            />
                                                          </div>
                                                          <div className="flex-1 min-w-0">
                                                            <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                                                              {r?.name}
                                                            </p>
                                                            <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                                              {r.unread} unanswered messages.
                                                            </p>
                                                            <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                                              Last Message <br /> {formatLastMessageTime(r.lastMessageTime)}
                                                            </p>
                                                          </div>
                                                          <div
                                                            className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white cursor-pointer"
                                                            onClick={() => handleSelectNumber(r)}
                                                          >
                                                            <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                              <path fillRule="evenodd" clipRule="evenodd" d="M12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 13.4811 3.09753 14.8788 3.7148 16.1181C3.96254 16.6155 4.05794 17.2103 3.90163 17.7945L3.30602 20.0205C3.19663 20.4293 3.57066 20.8034 3.97949 20.694L6.20553 20.0984C6.78973 19.9421 7.38451 20.0375 7.88191 20.2852C9.12121 20.9025 10.5189 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75ZM1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C10.2817 22.75 8.65552 22.3463 7.21315 21.6279C6.99791 21.5207 6.77814 21.4979 6.59324 21.5474L4.3672 22.143C2.84337 22.5507 1.44927 21.1566 1.857 19.6328L2.4526 17.4068C2.50208 17.2219 2.47933 17.0021 2.37213 16.7869C1.65371 15.3445 1.25 13.7183 1.25 12ZM7.25 10.5C7.25 10.0858 7.58579 9.75 8 9.75H16C16.4142 9.75 16.75 10.0858 16.75 10.5C16.75 10.9142 16.4142 11.25 16 11.25H8C7.58579 11.25 7.25 10.9142 7.25 10.5ZM7.25 14C7.25 13.5858 7.58579 13.25 8 13.25H13.5C13.9142 13.25 14.25 13.5858 14.25 14C14.25 14.4142 13.9142 14.75 13.5 14.75H8C7.58579 14.75 7.25 14.4142 7.25 14Z" fill="#1C274C" />
                                                            </svg>
                                                          </div>
                                                        </div>
                                                      </li>
                                                    </>
                                                  );
                                                }
                                                return null; // Ensure to return null for unmatched conditions
                                              })
                                            )}
                                          </ul>
                                        </li>
                                      </>
                                    ))
                                  }
                                </ul>
                              </div>
                            </li>
                          </ul>
                        </div>

                      </div>
                    </>}


                </div>
                <div className="w-full p-1 w-1/1 sm:w-2/2 md:w-2/2 lg:w-2/2 xl:w-full">
                  <div className="p-4 max-w bg-white rounded-lg border shadow-md sm:p-4 dark:bg-gray-800 dark:border-gray-700 h-full" style={{ maxHeight: '340px', overflowY: 'auto' }}>
                    <div className="flex items-center mb-1">

                      <ShoppingCartIcon
                        aria-hidden="true"
                        className="h-6 w-6 text-gray-400 hover:text-gray-500"
                      /> <div className="ml-4 text-xl">Pedidos</div>
                    </div>
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead>
                        <tr>
                          <th scope="col" className="py-1 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                            Cliente
                          </th>
                          <th scope="col" className="px-3 py-1 text-left text-sm font-semibold text-gray-900">
                            Informações
                          </th>
                          <th scope="col" className="px-3 py-1 text-left text-sm font-semibold text-gray-900">
                            Status Pedido
                          </th>
                          <th scope="col" className="px-3 py-1 text-left text-sm font-semibold text-gray-900">
                            Horário 
                          </th>
                          <th scope="col" className="px-3 py-1 text-end text-sm font-semibold text-gray-900">
                            Ações
                          </th>
                          <th scope="col" className="px-3 py-1 text-left text-sm font-semibold text-gray-900">
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {people.map((person: any) => (
                          <tr key={person.email}>
                            <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-0">
                              <div className="flex items-center">
                                <div className="w-12 shrink-0">
                                  <img alt="" src={person.image} className="rounded-full" />
                                </div>
                                <div className="ml-2">
                                  <div className="font-medium text-gray-900">{person.name}</div>
                                  <div className="mt-1 text-gray-500">{person.telefone}</div>
                                </div>
                              </div>
                            </td>
                            <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                              <div className="text-gray-900">{person.entrega}</div>
                            </td>
                            <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                              <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                Aguardando Comfirmação
                              </span>
                            </td>
                            <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">{person.role}</td>
                            <td className="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                              <button className="text-indigo-600 hover:text-indigo-900">
                                Visualizar
                              </button>
                            </td>
                            <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500"></td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                  </div>


                </div>
              </div>


            </div>

          </div>
        </div>

      </div>

      <Modal
        show={showModalAddUser}
        size={"small"}
        fullscreen={true}
        title={
          <>
            <div className='text-center text-dark'>

              <button
                onClick={() => { setShowModalAddUser(false); }}
                style={{
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                  border: 'none',
                  background: 'none'
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="red"
                  strokeWidth="2"

                  strokeLinejoin="round"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </button>
            </div>
          </>
        }
        list={
          <>

            <div className='relative col-4 m-2 inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl dark:bg-gray-900 sm:my-8 sm:w-full sm:max-w-sm sm:p-6 sm:align-middle'>
              <div className="flex flex-col justify-center px-4 w-full">
                <h1 className="text-gray-800 font-bold text-xl mb-1">Create new account for one customer Service representative here.</h1>
                <div className="flex justify-center py-2 items-center">

                  <div className="w-full mt-4 ">
                    <div className="flex items-center py-2 px-3 mb-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 text-gray-400"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path

                          d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <input
                        type="text"
                        id="login-name"
                        className="w-full ml-2 appearance-none border-green-300 h-8 pl-2 border rounded text-base text-gray-700 placeholder-gray-400"
                        placeholder="Name"
                        value={nome}
                        onChange={(e) => { setNome(e.target.value) }}
                      />
                    </div>
                    <div className="flex items-center py-2 px-3 mb-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 text-gray-400"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path

                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                        />
                      </svg>
                      <input
                        type="text"
                        id="on-error-email"
                        className="w-full ml-2 appearance-none border-green-300 h-8 pl-2 border rounded text-base text-gray-700 placeholder-gray-400"
                        name="email"
                        value={email}
                        placeholder="Your email"
                        onChange={(e) => { handleDateEmail(e) }}
                      />

                    </div>
                    <div className="flex items-center py-2 px-3 mb-1">
                      <svg viewBox="0 0 32 32"
                        fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 text-gray-400"><g id="SVGRepo_bgCarrier"
                          stroke-width="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                        <g id="SVGRepo_iconCarrier">
                          <path d="M25 12h-1v-3.816c0-4.589-3.32-8.184-8.037-8.184-4.736 0-7.963 3.671-7.963 8.184v3.816h-1c-2.206 0-4 1.794-4 4v12c0 2.206 1.794 4 4 4h18c2.206 0 4-1.794 4-4v-12c0-2.206-1.794-4-4-4zM10 8.184c0-3.409 2.33-6.184 5.963-6.184 3.596 0 6.037 2.716 6.037 6.184v3.816h-12v-3.816zM27 28c0 1.102-0.898 2-2 2h-18c-1.103 0-2-0.898-2-2v-12c0-1.102 0.897-2 2-2h18c1.102 0 2 0.898 2 2v12zM16 18c-1.104 0-2 0.895-2 2 0 0.738 0.405 1.376 1 1.723v3.277c0 0.552 0.448 1 1 1s1-0.448 1-1v-3.277c0.595-0.346 1-0.985 1-1.723 0-1.105-0.895-2-2-2z"></path> </g></svg>

                      <input
                        type="password"
                        className="w-full ml-2 appearance-none border-green-300 h-8 pl-2 border rounded text-base text-gray-700 placeholder-gray-400"
                        name="password"
                        placeholder="Password"
                        value={passwordConfirmation}
                        onChange={(e) => { setPasswordConfirmation(e.target.value); }}
                      />
                    </div>
                    <div className="flex items-center py-2 px-3 mb-1">
                      <svg viewBox="0 0 32 32"
                        fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 text-gray-400"><g id="SVGRepo_bgCarrier"
                          stroke-width="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                        <g id="SVGRepo_iconCarrier">
                          <path d="M25 12h-1v-3.816c0-4.589-3.32-8.184-8.037-8.184-4.736 0-7.963 3.671-7.963 8.184v3.816h-1c-2.206 0-4 1.794-4 4v12c0 2.206 1.794 4 4 4h18c2.206 0 4-1.794 4-4v-12c0-2.206-1.794-4-4-4zM10 8.184c0-3.409 2.33-6.184 5.963-6.184 3.596 0 6.037 2.716 6.037 6.184v3.816h-12v-3.816zM27 28c0 1.102-0.898 2-2 2h-18c-1.103 0-2-0.898-2-2v-12c0-1.102 0.897-2 2-2h18c1.102 0 2 0.898 2 2v12zM16 18c-1.104 0-2 0.895-2 2 0 0.738 0.405 1.376 1 1.723v3.277c0 0.552 0.448 1 1 1s1-0.448 1-1v-3.277c0.595-0.346 1-0.985 1-1.723 0-1.105-0.895-2-2-2z"></path> </g></svg>
                      <input
                        type="password"
                        className="w-full ml-2 appearance-none border-green-300 h-8 pl-2 border rounded text-base text-gray-700 placeholder-gray-400"
                        name="password"
                        placeholder="Validite password"
                        value={password}
                        onChange={(e) => handlePasswordChange(e)}
                      />

                    </div>
                    <div className="grid w-full h-1 grid-cols-12 gap-4 mt-3">
                      <div className={` h-full col-span-3 ${getStrengthBarColor(1)} bg-gray-200 rounded `}></div>
                      <div className={` h-full col-span-3 ${getStrengthBarColor(2)} bg-gray-200 rounded `}></div>
                      <div className={` h-full col-span-3 ${getStrengthBarColor(3)} bg-gray-200 rounded `}></div>
                      <div className={` h-full col-span-3 ${getStrengthBarColor(4)} bg-gray-200 rounded `}></div>
                    </div>
                    {passwordConfirmation !== '' ?
                      validPassword && passwordConfirmation === password ?
                        <>
                          <div className="mt-2 text-green-500">Valid password</div>
                        </>
                        :
                        <>
                          <div className="mt-2 text-red-500">Invalid password</div>
                        </>
                      :
                      <>
                      </>
                    }

                  </div>
                </div>
              </div>
              <div className="p-5">
                <button

                  className="rounded-lg bg-green-500 px-4 py-2 text-center text-base font-semibold text-white shadow-md outline-none ring-green-500 ring-offset-2 transition hover:bg-green-400 focus:ring-2 md:w-32"
                  onClick={() => { register(instanciaSelecionada) }}
                >
                  Create User
                </button>
                <div className="block">
                  {showAlert ? <p>{alert}</p> : ''}
                  <small className="block" >
                    {" "}
                    I agree to the{" "}
                    <button className="underline" onClick={() => navigate("/")}>
                      Terms and Conditions
                    </button>
                  </small>
                </div>

              </div>
            </div>

          </>
        }
      />
      <Modal
        show={showModalAtendentes}
        size={"large"}
        fullscreen={false}
        title={
          <>
            <div className='text-center text-dark'>

              <button
                onClick={() => { setShowModalAtendentes(false) }}
                style={{
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                  border: 'none',
                  background: 'none'
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="red"
                  strokeWidth="2"

                  strokeLinejoin="round"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </button>
            </div>
          </>
        }
        list={
          <>

            <div className='relative col-4 m-2 flex px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg'>
              <div className="flex flex-col justify-center px-4 w-full">
                <h1 className="text-gray-800 font-bold text-xl mb-1">Selecione os departamentos onde o atendente {atendenteSelecionado.nome} está alocado. Você pode escolher um ou mais departamentos.</h1>
                <div className="flex justify-center items-center w-full">

                  <div className="flex items-center py-2 px-3 mb-1 bg-white"></div>
                  <div className="w-full bg-white">

                    <MultiSelectComboBox
                      className="my-custom-multi-select bg-white w-full"
                      label="Instancias"
                      itemLabelPath="info.response.info.Pushname"
                      itemIdPath="info.response.info.Wid"
                      items={cookies.get('instancias')}
                      onChange={handleSelectionInstanceChange}
                      selectedItems={selectedInstanceItems}
                    />

                    {selectedInstanceItems.length > 0 ?
                      <>
                        {selectedInstanceItems.map((r: any) => {

                          return (<>
                            <MultiSelectComboBox
                              className="my-custom-multi-select bg-white w-full"
                              label={`Departamentos ${r.info.response.info.Pushname} `}
                              itemLabelPath="setor"
                              itemIdPath="id"
                              items={r.categoria_atendente}
                              onChange={handleSelectionChange}
                              selectedItems={selectedItems}
                            />
                          </>)
                        })}
                      </>
                      :
                      <></>
                    }



                    <button

                      className="rounded-lg bg-green-500 px-4 py-2 text-center text-base font-semibold text-white shadow-md outline-none ring-green-500 ring-offset-2 transition hover:bg-green-400 focus:ring-2 md:w-32 mt-12"
                      onClick={() => { alocarAtendentes() }}
                    >
                      Salvar
                    </button>

                  </div>
                </div>
              </div>
            </div>
          </>
        }
        footer={
          <>
            <br />
          </>
        }
      />
      <Modal
        show={showModal}
        size={"small"}
        fullscreen={false}
        title={
          <>
            <div className='text-center text-dark'>

              <button
                onClick={() => { clickClose() }}
                style={{
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                  border: 'none',
                  background: 'none'
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="red"
                  strokeWidth="2"

                  strokeLinejoin="round"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </button>
            </div>
          </>
        }
        list={
          <>

            <div className='text-center text-dark'>
              {!status ?
                <>
                  {base64 !== "" ? <img
                    className="w-full"
                    src={base64}
                  />
                    :
                    <svg
                      aria-hidden="true"
                      className="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-green-500"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                  }
                </>
                :
                <div className='text-center text-dark'>


                  O número máximo de telefones para seu plano já foi registrado.
                </div>
              }
              <span className="visually-hidden"></span>

            </div>
          </>
        }
        footer={
          <>
            <br />
          </>
        }
      />
      <Modal
        show={showDisconnect}
        size={"small"}
        fullscreen={false}
        title={
          <>
            <div className='text-center text-dark'>

              <button
                onClick={() => { setShowDisconnect(false) }}
                style={{
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                  border: 'none',
                  background: 'none'
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="red"
                  strokeWidth="2"

                  strokeLinejoin="round"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </button>
            </div>
          </>
        }
        list={
          <>
            <div className='text-center text-dark'> Tem certeza de que deseja desvincular sua conta do WhatsApp?

              <div className='text-center text-dark'>
                <button
                  onClick={() => handleDisconnect()}
                  className="mt-6 rounded-lg bg-red-500 px-4 py-2 text-center text-base font-semibold text-white shadow-md outline-none ring-green-500 ring-offset-2 transition hover:bg-red-400 focus:ring-2 md:w-32"
                >
                  Desvincular
                </button>
                <button
                  onClick={() => setShowDisconnect(false)}
                  className="mt-6 ml-4 rounded-lg bg-blue-500 px-4 py-2 text-center text-base font-semibold text-white shadow-md outline-none ring-red-500 ring-offset-2 transition hover:bg-blue-400 focus:ring-2 md:w-32"
                >
                  Voltar
                </button>
              </div>
              <span className="visually-hidden"></span>

            </div>
          </>
        }
        footer={
          <>
            <br />
          </>
        }
      />

      <Modal
        show={modalConfig}
        size={"fullscreen"}
        fullscreen={true}
        title={
          <>
            <div className='text-center text-dark' >
              <button
                onClick={() => { setModalConfig(false) }}
                style={{
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                  border: 'none',
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="red"
                  strokeWidth="2"

                  strokeLinejoin="round"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </button>
            </div>
          </>
        }
        list={
          <>
            <BotConfig idInstance={instancia[0]} />
          </>
        }

      />

      <>
        <div id="chat-container" className={`${showChat ? '' : 'hidden'} fixed bottom-0 right-4 w-96`}>
          <div className="bg-white shadow-md rounded-lg max-w-lg w-full">
            <div className="p-4 border-b bg-green-500 text-white rounded-t-lg flex justify-between items-center">
              <div>
                <img
                  className="w-10 h-10 rounded-full"
                  src={`${SelectedContactImage !== undefined ? SelectedContactImage : "./Anoni.jpg"}`}
                />
              </div>
              <div className="ml-4">
                <p className="text-white">{SelectedContactName}</p>

              </div>
              <div>
                {/*   <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width={24}
                  height={24}
                >
                  <path
                    fill="#FFFFFF"
                    d="M15.9 14.3H15l-.3-.3c1-1.1 1.6-2.7 1.6-4.3 0-3.7-3-6.7-6.7-6.7S3 6 3 9.7s3 6.7 6.7 6.7c1.6 0 3.2-.6 4.3-1.6l.3.3v.8l5.1 5.1 1.5-1.5-5-5.2zm-6.2 0c-2.6 0-4.6-2.1-4.6-4.6s2.1-4.6 4.6-4.6 4.6 2.1 4.6 4.6-2 4.6-4.6 4.6z"
                  />
                </svg> */}
              </div>
              <div className="ml-6">
                {/*  <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width={24}
                  height={24}
                >
                  <path
                    fill="#FFFFFF"
                    d="M1.816 15.556v.002c0 1.502.584 2.912 1.646 3.972s2.472 1.647 3.974 1.647a5.58 5.58 0 0 0 3.972-1.645l9.547-9.548c.769-.768 1.147-1.767 1.058-2.817-.079-.968-.548-1.927-1.319-2.698-1.594-1.592-4.068-1.711-5.517-.262l-7.916 7.915c-.881.881-.792 2.25.214 3.261.959.958 2.423 1.053 3.263.215l5.511-5.512c.28-.28.267-.722.053-.936l-.244-.244c-.191-.191-.567-.349-.957.04l-5.506 5.506c-.18.18-.635.127-.976-.214-.098-.097-.576-.613-.213-.973l7.915-7.917c.818-.817 2.267-.699 3.23.262.5.501.802 1.1.849 1.685.051.573-.156 1.111-.589 1.543l-9.547 9.549a3.97 3.97 0 0 1-2.829 1.171 3.975 3.975 0 0 1-2.83-1.173 3.973 3.973 0 0 1-1.172-2.828c0-1.071.415-2.076 1.172-2.83l7.209-7.211c.157-.157.264-.579.028-.814L11.5 4.36a.572.572 0 0 0-.834.018l-7.205 7.207a5.577 5.577 0 0 0-1.645 3.971z"
                  />
                </svg> */}
              </div>

              <div className="ml-6">
                {/*   <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width={24}
                  height={24}
                >
                  <path
                    fill="#FFFFFF"

                    d="M12 7a2 2 0 1 0-.001-4.001A2 2 0 0 0 12 7zm0 2a2 2 0 1 0-.001 3.999A2 2 0 0 0 12 9zm0 6a2 2 0 1 0-.001 3.999A2 2 0 0 0 12 15z"
                  />
                </svg> */}
              </div>
              <button
                id="close-chat"
                onClick={() => { setShowChat(false) }}
                className="text-gray-300 hover:text-gray-400 focus:outline-none focus:text-gray-400"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path

                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div id="chatbox" className="p-4 h-80 overflow-y-auto">
              {Conversas.length > 0 && Conversas.map((r: any) => {
                return (
                  <>
                    {JSON.parse(r?.data)['Type'] === "receveid_message" ?
                      <>
                        {SelectedContact === JSON.parse(r.data)['Body']['Info']['SenderJid'].split('@')[0] ?
                          <>
                            <div className="flex justify-center mb-4" >
                              <div
                                className="rounded py-2 px-4"
                                style={{ backgroundColor: "#FCF4CB" }}
                              >
                                <p className="text-xs">{new Date(r.created_at).toLocaleString('pt-BR')}</p>
                              </div>
                            </div>
                            <div className="flex mb-2" style={{ paddingLeft: "50px" }}>
                              <div
                                className="rounded py-2 px-3"
                                style={{
                                  backgroundColor: "#F2F2F2", width: "250px"
                                }}
                              >
                                <p className="text-sm text-teal">{JSON.parse(r.data)['Body']['Info']['PushName']}</p>
                                <p className="text-sm mt-1">
                                  {wrapText(JSON.parse(r.data)['Body']['Info']["Source"]['message']["conversation"], 32)}
                                </p>
                                <p className="text-right text-xs text-grey-dark mt-1">
                                  {formatLastMessageTime(JSON.parse(r.data)['Body']['Info']["Source"]['messageTimestamp'])}
                                </p>
                              </div>
                            </div>

                          </>
                          :
                          <>

                          </>}
                      </>
                      : <>
                        {JSON.parse(r.data)['Type'] === "send_message" ?
                          <>

                            {SelectedContact === JSON.parse(r.data)['Body']['Info']['RemoteJid'].split('@')[0] ?
                              <>
                                <div className="flex justify-center mb-4" >
                                  <div
                                    className="rounded py-2 px-4"
                                    style={{ backgroundColor: "#FCF4CB" }}
                                  >
                                    <p className="text-xs">{new Date(r.created_at).toLocaleString('pt-BR')}</p>
                                  </div>
                                </div>
                                <div className="flex justify-end mb-2" style={{ width: "250px", }}>
                                  <div
                                    className="rounded py-2 px-3"
                                    style={{ backgroundColor: "#E2F7CB" }}
                                  >
                                    <p className="text-sm mt-1">{renderConversation(JSON.parse(r.data)['Body']['Info']["Source"]['message']["conversation"])}</p>
                                    <p className="text-right text-xs text-grey-dark mt-1">
                                      {formatLastMessageTime(JSON.parse(r.data)['Body']['Info']["Source"]['messageTimestamp'])}
                                    </p>
                                  </div>
                                </div>
                              </>
                              :
                              <>
                              </>
                            }
                          </>
                          :
                          <>
                          </>
                        }
                      </>
                    }

                  </>);
              })}
              <div id="chatbox" className="p-4 h-80 overflow-y-auto">

                {/* Data/Hora da Mensagem */}
                <div className="flex justify-center mb-4">
                  <div
                    className="rounded py-2 px-4"
                    style={{ backgroundColor: "#FCF4CB" }}
                  >
                    <p className="text-xs">
                      {new Date().toLocaleString('pt-BR')}
                    </p>
                  </div>
                </div>

                {/* "Bolha" da Mensagem Enviada */}
                <div className="flex justify-end mb-2" style={{ width: "250px" }}>
                  <div
                    className="rounded py-2 px-3"
                    style={{ backgroundColor: "#E2F7CB" }}
                  >
                    <p className="text-sm mt-1">
                      {/* Substituir pela mensagem enviada (ex.: mensagemResponse) */}
                      {renderConversation(mensagemResponse)}
                    </p>
                    <p className="text-right text-xs text-grey-dark mt-1">
                      10:01
                    </p>
                  </div>
                </div>
              </div>
            </div >

            <div>
              <div className="p-4 border-t flex items-start space-x-2 bg-gray-100">
                <EmojiPicker onEmojiSelect={onEmojiSelectResponse} />
                <textarea
                  placeholder="Type a message"
                  className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 resize-none"
                  rows={1}
                  value={mensagemResponseOriginal}
                  onKeyDown={handleKeyDown}
                  onChange={handleChange}
                />
                <button
                  className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition duration-300"
                >
                  Send
                </button>
              </div>
              {/* <div className="w-full justify-start">
                <label htmlFor="search" className="block text-center font-medium text-gray-700" style={{ background: "#dad3cc" }}>
                  Response Message
                </label>
                <div className="relative justify-end mb-2">
                  <div
                    className="rounded py-2 px-3"
                    style={{ backgroundColor: "#E2F7CB", maxHeight: '100px', overflowY: 'auto' }}
                  >
                    <p className="text-sm mt-1 whitespace-pre-line">{mensagemResponse}</p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </>
    </>
  );

}

export default Home;
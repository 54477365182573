
import React, { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';
import SideBar from './SideBar';
import SettingsNav from './SettingsNav';
import NotificationsNav from './NotificationsNav';
import Auth from "../services/Auth";
import { useNavigate } from 'react-router-dom'; // import do hook
import Cookies from 'universal-cookie';
import ModalChat from './Modal/ModalChat';
import chatProApi from '../services/ChatProApi';
import UserService from '../services/UserApi';
import ScrollableContainer from '../components/Utils/ScrollableContainer';
import apiService from '../services/Api';
function NavBar() {

    let navigate = useNavigate();
    const location = useLocation();
    const cookies = new Cookies();

    const [SideBarShow, setSideBarShow] = useState<boolean>(false);
    const [showConversa, setShowConversa] = useState<boolean>(true);
    const [SettingsShow, setSettingsShow] = useState(0);
    const [NotificationsShow, setNotificationsShow] = useState(0);
    const [ChatsShow, setChatsShow] = useState(0);
    const [ChatStatus, setChatStatus] = useState(0);
    const [ImgPerfil, setImgPerfil] = useState("");
    const [UnRead, setUnRead] = useState(0);
    const [SelectedContact, setSelectedContact] = useState(0);
    const [SelectedContactImage, setSelectedContactImage] = useState("");
    const [SelectedContactName, setSelectedContactName] = useState("");
    const [Conversas, setConversas] = useState({} as any);
    const [user, setUser] = useState({} as any);
    const [Contatos, setContatos] = useState([] as any);
    var [nome, setNome] = useState(0);

    useEffect(() => {
        // chatProApi.getStatus().then((status: any) => {

        const subscription = Auth.observable.onToken().subscribe((data: any) => {
            const bottomScroll = document.getElementById("scrollableDiv");

            if (cookies.get("token") !== null) {
            
                setNome(cookies.get("user")?.nome);
                setUser(cookies.get('user'));
            }      
        });
        return subscription.unsubscribe;

    }, []);
    useEffect(() => {
        // Define a função que será chamada
        const intervalo = setInterval(() => {

            if (cookies.get("token") !== null) {
                if (cookies.get("user") !== undefined) {

                    if (cookies.get("user").permissions[0].name !== "Visitante") {

                        chatProApi.getStatus().then((status: any) => {
                            if(status?.data !== undefined)
                                if (status.data.response?.connected) {
                                    chatProApi.listChats().then((contatos: any) => {
                                        setContatos(contatos.data.response);
                                        { console.log(contatos.data.response) }
                                        var phone = status.data.response.info['Wid'].split('@');
                                        
                                            chatProApi.getProfile({ 'number': phone[0] }).then((r) => {
                                                if(r?.data?.response['imgUrl'] !== undefined)
                                                {
                                                    setImgPerfil(r?.data?.response['imgUrl']);
                                                }
                                            })
                                            setChatStatus(1);
                                    
                                    
                                    });


                                }
                        });
                    }
                    
                }
                
            }
        }, 220 * 2000);

        // Função de limpeza que será chamada quando o componente for desmontado
        return () => clearInterval(intervalo);
    }, []);




    if (!['/sign-in', '/sign-up'].includes(location.pathname)) {
        return (
            <>
                {cookies.get('token') === undefined ?
                    <>
                        <div>
                            <nav className="bg-white shadow py-4 ">
                                <div className="px-8 mx-auto max-w-7xl">
                                    <div className="flex items-center justify-between h-16">
                                        <div className=" flex items-center">
                                            <button className="flex flex-col text-3xl font-black leading text-gray-800 uppercase font-bebas-neue sm:text-3xl md:text-3x1 lg:text-3x1" onClick={() => navigate("/")}>
                                                CHAT BR
                                            </button>
                                            <div className="hidden md:block">
                                                <div className="flex items-baseline ml-10 space-x-4">
                                                    <button
                                                        className="text-gray-400 px-3 py-2 rounded-md text-sm font-medium whitespace-nowrap hover:text-gray-800 "
                                                        onClick={() => navigate("/sign-up")}
                                                    >
                                                        Sign Up
                                                    </button>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="block">
                                            <div className="flex items-center ml-4 md:ml-6">
                                                <button className="text-gray-400 hover:text-gray-800  px-0 py-1 rounded-md text-sm font-medium"
                                                    onClick={() => navigate('/sign-in')}
                                                >
                                                    Sign In
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </nav>
                        </div>
                    </>
                    :
                    <>

                        <header className="z-40 bg-white flex items-center justify-between w-full h-16">
                            {cookies.get("user").permissions[0].name !== "Visitante" ?
                                <>
                                    <div className="ml-6 block">
                                        <button
                                            className={`flex items-center p-2 text-green-500 bg-white rounded-full shadow text-md active:bg-blue-gray-500/30 hover:text-gray-700 `}
                                            onClick={() => {
                                                if (SideBarShow === false) {
                                                    setSettingsShow(0);
                                                    setNotificationsShow(0);
                                                    setSideBarShow(true);
                                                } else {
                                                    setSettingsShow(0);
                                                    setNotificationsShow(0);
                                                    setSideBarShow(false);
                                                }
                                            }} >
                                            <svg
                                                width={20}
                                                height={20}
                                                className="text-gray-400"
                                                fill="currentColor"
                                                viewBox="0 0 1792 1792"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M1664 1344v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45z"></path>
                                            </svg>
                                        </button>
                                    </div>
                                </>
                                :
                                <></>
                            }
                            <div className="relative z-20 flex flex-col justify-end h-full px-3 md:w-full">
                                <div className="relative flex items-center justify-end w-full p-1 space-x-4">

                                    <button onClick={() => {
                                        if (NotificationsShow === 0) {
                                            setNotificationsShow(1);
                                            setSettingsShow(0);
                                            setSideBarShow(false);
                                        }
                                        else {
                                            setNotificationsShow(0);
                                            setSettingsShow(0);
                                            setSideBarShow(false);
                                        }
                                    }} aria-expanded="false" aria-haspopup="menu" id=":r2:" className="relative middle none font-sans font-medium text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none w-10 max-w-[40px] h-10 max-h-[40px] rounded-lg text-xs text-gray-500 hover:bg-blue-gray-500/10 active:bg-blue-gray-500/30 hover:text-gray-700" type="button">
                                        <span className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2"> <div className="absolute w-5 h-5 mt-1 bg-blue-400 rounded-full flex items-center justify-center text-white text-1xl">2</div>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" className="mt-2 h-8 w-8 text-gray-500">
                                                <path  d="M5.25 9a6.75 6.75 0 0113.5 0v.75c0 2.123.8 4.057 2.118 5.52a.75.75 0 01-.297 1.206c-1.544.57-3.16.99-4.831 1.243a3.75 3.75 0 11-7.48 0 24.585 24.585 0 01-4.831-1.244.75.75 0 01-.298-1.205A8.217 8.217 0 005.25 9.75V9zm4.502 8.9a2.25 2.25 0 104.496 0 25.057 25.057 0 01-4.496 0z" clipRule="evenodd" />
                                            </svg>
                                        </span>
                                    </button>
                                    {nome}
                                    <span className="w-1 h-8 bg-gray-200 rounded-lg"></span>
                                    <button
                                        className="flex items-center text-gray-500 text-md"
                                        onClick={() => {
                                            if (SettingsShow === 0) {
                                                setSettingsShow(1);
                                                setSideBarShow(false);
                                                setNotificationsShow(0);
                                            }
                                            else {
                                                setSettingsShow(0);
                                                setSideBarShow(false);
                                                setNotificationsShow(0);
                                            }
                                        }} >

                                        <img
                                            alt="profil"
                                            src={ImgPerfil !== undefined ? ImgPerfil : "./Anoni.jpg"}
                                            className="mx-auto object-cover rounded-full h-10 w-10  mr-2"
                                        />

                                        <svg
                                            width={20}
                                            height={20}
                                            className="ml-2 text-gray-400"
                                            fill="currentColor"
                                            viewBox="0 0 1792 1792"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path d="M1408 704q0 26-19 45l-448 448q-19 19-45 19t-45-19l-448-448q-19-19-19-45t19-45 45-19h896q26 0 45 19t19 45z"></path>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </header>
                        
                    </>

                }
                <SideBar setShow={setSideBarShow} show={SideBarShow} />
                <SettingsNav show={SettingsShow} />
                <NotificationsNav show={NotificationsShow} />
                
            </>);

    }
    else {
        return (<></>);
    }
}
export default NavBar;